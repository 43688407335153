import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromDevices from './devices.reducer';
import { DevicesEffects } from './devices.effects';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromDevices.devicesFeatureKey, fromDevices.reducer),
    EffectsModule.forFeature([DevicesEffects]),
  ],
})
export class DevicesNgrxModule {
  constructor() {}
}
