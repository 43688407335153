import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, map } from 'rxjs';

import {
  Group,
  Groups,
  Subgroup,
  GroupsService,
  InputCreateGroup,
  InputUpdateGroup,
  InputCreateSubgroup,
  InputUpdateSubgroup,
  Configuration,
  BASE_PATH,
} from 'app/core/api-swagger/trackagri-api';

import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GroupsApiRawService extends GroupsService {
  // #region -> service basics

  /** */
  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super(httpClient, environment.trackagriApiUrl, configuration);
  }

  // #endregion

  // #region -> create

  /** */
  public create_groups$(input: InputCreateGroup): Observable<Group> {
    return this.trackagriGroupsPost(input);
  }

  /** */
  public create_subgroup$(subgroup: InputCreateSubgroup, group_id: string): Observable<Subgroup> {
    return this.trackagriGroupsGroupIdSubgroupPost(subgroup, group_id);
  }

  // #endregion

  // #region -> read

  /** */
  public fetch_groups$(): Observable<Groups> {
    return this.trackagriGroupsGet().pipe(
      map(results => ({ groups: results.groups.sort((a: Group, b: Group) => (a.name || '').localeCompare(b.name || '')) })),
      map(results => ({
        groups: results.groups.map((group: Group) => ({
          id: group.id,
          name: group.name,
          subgroups: group.subgroups.sort((a: Subgroup, b: Subgroup) => (a.name || '').localeCompare(b.name || '')),
        })),
      }))
    );
  }

  /** */
  public fetch_group$(group_id: string): Observable<Group> {
    return this.trackagriGroupsGroupIdGet(group_id).pipe(
      map((group: Group) => ({
        id: group.id,
        name: group.name,
        subgroups: group.subgroups.sort((a: Subgroup, b: Subgroup) => (a.name || '').localeCompare(b.name || '')),
      }))
    );
  }

  /** */
  public fetch_subgroup$(subgroup_id: string): Observable<Subgroup> {
    return this.trackagriSubgroupsSubgroupIdGet(subgroup_id);
  }

  // #endregion

  // #region -> update

  /** */
  public update_groups$(input: InputUpdateGroup, group_id: string): Observable<Group> {
    return this.trackagriGroupsGroupIdPut(input, group_id);
  }

  /** */
  public update_subgroups$(input: InputUpdateSubgroup, subgroup_id: string): Observable<Subgroup> {
    return this.trackagriSubgroupsSubgroupIdPut(input, subgroup_id);
  }

  // #endregion

  // #region -> delete

  /** */
  public delete_group$(group_id: string): Observable<any> {
    return this.trackagriGroupsGroupIdDelete(group_id);
  }

  /** */
  public delete_subgroup$(subgroup_id: string): Observable<any> {
    return this.trackagriSubgroupsSubgroupIdDelete(subgroup_id);
  }

  // #endregion
}
