import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastControllerService } from 'app/core/services/providers';
import { switchMap, catchError, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ServiceBooksActions from './service_books.actions';
import { TranslateService } from '@ngx-translate/core';
import { TrackagriApiService } from 'app/core/api';

import { marker } from '@colsen1991/ngx-translate-extract-marker';

@Injectable()
export class ServiceBooksEffects {
  read_service_books$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ServiceBooksActions.read_service_books),
      tap(action => console.log({ effect: action.type, action })),
      switchMap(props =>
        this.trackagriApiService.fetch_service_book$(props.imei).pipe(
          map(data => ServiceBooksActions.read_service_books_success({ data: data })),
          catchError(error => {
            console.log({ error });
            return of(ServiceBooksActions.read_service_books_fail({ data: props }));
          })
        )
      )
    );
  });

  read_service_books_success$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ServiceBooksActions.read_service_books_success),
        tap(action => console.log({ effect: action.type, action }))
      );
    },
    { dispatch: false }
  );

  upsert_service_books$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ServiceBooksActions.upsert_service_books),
      tap(action => console.log({ effect: action.type, action })),
      switchMap(props =>
        this.trackagriApiService.update_service_book$(props.data, props.data.imei).pipe(
          map(() => ServiceBooksActions.upsert_service_books_success({ data: props.data })),
          tap(() => {
            const message = this.translateService.instant(marker('SERVICE_BOOKS.UPSERT.UPDATED_MESSAGE'), props.data);
            this.toastControllerService.success(message);
          }),
          catchError(error => {
            console.log({ error });
            const message = this.translateService.instant(marker('SERVICE_BOOKS.UPSERT.ERROR_MESSAGE'));
            this.toastControllerService.danger(message);
            return of(ServiceBooksActions.read_service_books_fail({ data: props.data }));
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private trackagriApiService: TrackagriApiService,
    private translateService: TranslateService,
    private toastControllerService: ToastControllerService
  ) {}
}
