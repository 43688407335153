<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="danger" (click)="handleCancel()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ 'USERS.CHANGE_PASSWORD.TITLE' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="form" (ngSubmit)="handleOk(form.value)">
    <ion-item [disabled]="'true'">
      <ion-label>{{ 'USERS.DETAIL.NAME' | translate }}</ion-label>
      <ion-text color="text" class="ion-text-end">{{ user.name }}</ion-text>
    </ion-item>

    <ion-item [disabled]="'true'">
      <ion-label>{{ 'USERS.DETAIL.EMAIL' | translate }}</ion-label>
      <ion-text lass="ion-text-end">{{ user.email }}</ion-text>
    </ion-item>

    <ion-item>
      <ion-input class="ion-text-end" formControlName="password" type="password" [label]="'USERS.DETAIL.PASSWORD' | translate"></ion-input>
    </ion-item>
    <div class="validation-errors">
      <ng-container class="ion-text-center" *ngFor="let validation of validation_messages.password">
        <div class="error-message" *ngIf="form.get('password').hasError(validation.type)">
          <b>{{ validation.message }}</b>
        </div>
      </ng-container>
    </div>

    <ion-item>
      <ion-input
        class="ion-text-end"
        formControlName="confirm_password"
        type="password"
        [label]="'USERS.DETAIL.CONFIRM_PASSWORD' | translate"
      ></ion-input>
    </ion-item>
    <div class="validation-errors">
      <ng-container class="ion-text-center" *ngFor="let validation of validation_messages.confirm_password">
        <div class="error-message" *ngIf="form.get('confirm_password').hasError(validation.type)">
          <b>{{ validation.message }}</b>
        </div>
      </ng-container>
    </div>

    <ion-button expand="full" fill="outline" color="success" type="submit" [disabled]="!form.valid">
      {{ 'USERS.CHANGE_PASSWORD.BUTTON_UPDATE' | translate }}
    </ion-button>
  </form>
</ion-content>
