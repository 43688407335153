import { createAction, props } from '@ngrx/store';
import { PostDevices, UpdateDevice } from 'app/core/api-swagger/device-api';
import { TrackagriDevice } from 'app/models/devices/trackagri-device.class';

export enum devicesActionTypes {
  CreateDevice = '[Devices] CREATE',
  CreateDeviceSuccess = '[Devices] CREATE => 201',
  ReadDevices = '[Devices] READ',
  ReadDevicesSuccess = '[Devices] READ => 200',
  UpdateDevice = '[Devices] UPDATE',
  UpdateDeviceSuccess = '[Devices] UPDATE => 201',
  DeleteDevice = '[Devices] DELETE',
  DeleteDeviceSuccess = '[Devices] DELETE => 200',
}

export const create_device = createAction(devicesActionTypes.CreateDevice, props<{ data: PostDevices }>());
export const create_device_success = createAction(devicesActionTypes.CreateDeviceSuccess, props<{ data: TrackagriDevice }>());
export const read_devices = createAction(devicesActionTypes.ReadDevices);
export const read_devices_success = createAction(devicesActionTypes.ReadDevicesSuccess, props<{ data: TrackagriDevice[] }>());
export const update_device = createAction(devicesActionTypes.UpdateDevice, props<{ id: number; data: UpdateDevice }>());
export const update_device_success = createAction(devicesActionTypes.UpdateDeviceSuccess, props<{ data: TrackagriDevice }>());
export const delete_device = createAction(devicesActionTypes.DeleteDevice, props<{ data: TrackagriDevice }>());
export const delete_device_success = createAction(devicesActionTypes.DeleteDeviceSuccess, props<{ key: number }>());
