import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import {
  BASE_PATH,
  Configuration,
  InputCreateRole,
  InputUpdateRole,
  Role,
  Roles,
  RolesService,
} from 'app/core/api-swagger/identity-server';

import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RolesApiRawService extends RolesService {
  // #region -> (service basics)

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super(httpClient, environment.identityServerUrl, configuration);
  }

  // #endregion

  // #region -> create

  /** */
  public create_role$(role: InputCreateRole): Observable<Role> {
    return this.identityServerRolesPost(role);
  }

  // #endregion

  // #region -> read

  public fetch_role$(id: string): Observable<Role> {
    return this.identityServerRolesRoleIdGet(id);
  }

  public fetch_roles$(no_scopes?: boolean): Observable<Roles> {
    return this.identityServerRolesGet();
  }

  // #endregion

  // #region -> update

  /** */
  public update_role$(role: InputUpdateRole, id: string): Observable<Role> {
    return this.identityServerRolesRoleIdPut(role, id);
  }

  // #endregion

  // #region -> delete

  /** */
  public delete_role$(id: string): Observable<any> {
    return this.identityServerRolesRoleIdDelete(id);
  }

  // #endregion
}
