/**
 * Measurement API
 * This document is the reference documentation of measurement api 
 *
 * OpenAPI spec version: NEXT
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type Aggregations = 'count' | 'distinct' | 'integral' | 'mean' | 'median' | 'mode' | 'spread' | 'stddev' | 'sum' | 'bottom' | 'first' | 'last' | 'max' | 'min' | 'percentile' | 'sample' | 'top' | 'none';

export const Aggregations = {
    Count: 'count' as Aggregations,
    Distinct: 'distinct' as Aggregations,
    Integral: 'integral' as Aggregations,
    Mean: 'mean' as Aggregations,
    Median: 'median' as Aggregations,
    Mode: 'mode' as Aggregations,
    Spread: 'spread' as Aggregations,
    Stddev: 'stddev' as Aggregations,
    Sum: 'sum' as Aggregations,
    Bottom: 'bottom' as Aggregations,
    First: 'first' as Aggregations,
    Last: 'last' as Aggregations,
    Max: 'max' as Aggregations,
    Min: 'min' as Aggregations,
    Percentile: 'percentile' as Aggregations,
    Sample: 'sample' as Aggregations,
    Top: 'top' as Aggregations,
    None: 'none' as Aggregations
};