import { Injectable, Inject } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

/**
 * A simple settings/config class for storing key/value pairs with persistence.
 */
@Injectable()
export class Settings {
  private SETTINGS_KEY = '_settings';

  settings: any;
  _readyPromise: Promise<any>;
  defaults: any;

  constructor(public storage: Storage, @Inject('defaults') _defaults: any) {
    this.defaults = _defaults;
  }

  load() {
    return this.storage.get(this.SETTINGS_KEY).then(value => {
      if (value) {
        this.settings = value;
        return this._mergeDefaults(this.defaults);
      } else {
        return this.setAll(this.defaults).then(val => {
          this.settings = val;
        });
      }
    });
  }

  _mergeDefaults(defaults: any) {
    for (const k in defaults) {
      if (!(k in this.settings)) {
        this.settings[k] = defaults[k];
      }
    }
    return this.setAll(this.settings);
  }

  merge(settings: any) {
    for (const k in settings) {
      if (true) {
        this.settings[k] = settings[k];
      }
    }
    return this.save();
  }

  setValue(key: string, value: any) {
    this.settings[key] = value;
    return this.storage.set(this.SETTINGS_KEY, this.settings);
  }

  setAll(value: any) {
    return this.storage.set(this.SETTINGS_KEY, value);
  }

  getValue(key: string) {
    return this.storage.get(this.SETTINGS_KEY).then(settings => {
      return settings[key];
    });
  }

  save() {
    return this.setAll(this.settings);
  }

  get allSettings() {
    return this.settings;
  }
}
