import { createAction, props } from '@ngrx/store';
import { InputData } from '../../models/InputData';
import { Timeserie } from 'app/core/api-swagger/measurement_api/model/models';
import { Imeis, Dates } from '../../models/Trackagri';

export enum TrackagriActionTypes {
  GetTrackagriData = '[Trackagri] Get data',
  GetTrackagriDataSuccess = '[Trackagri] Get data => 200',
  UpdateImeisTrackagri = '[Trackagri] Update imeis',
  UpdateDatesTrackagri = '[Trackagri] Update dates',
  ClearDownloadedTrackagris = '[Trackagri] Clear downloaded',
}

export const get_trackagri_data = createAction(TrackagriActionTypes.GetTrackagriData, props<{ input_data: InputData }>());
export const get_trackagri_data_success = createAction(TrackagriActionTypes.GetTrackagriDataSuccess, props<{ data: Timeserie }>());
export const update_imeis_trackagri = createAction(TrackagriActionTypes.UpdateImeisTrackagri, props<{ imeis: Imeis }>());
export const update_dates_trackagri = createAction(TrackagriActionTypes.UpdateDatesTrackagri, props<{ dates: Dates }>());
export const clear_trackagri_downloaded = createAction(TrackagriActionTypes.ClearDownloadedTrackagris);
