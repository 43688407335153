import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, tap, map, mergeMap, takeUntil } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import * as DataAvailabilityActions from './data_availability.actions';
import { Timeserie, OutputFormat, Aggregations, TrackagriFields } from 'app/core/api-swagger/measurement_api';
import { DataAvailability, InputData, ExtendedMeasurement } from '../../models';
import * as _ from 'lodash';
import { parseISO } from 'date-fns';
import { MeasurementsApiService } from 'app/core/api';

@Injectable()
export class DataAvailabilityEffects {
  get_data_availability_data$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataAvailabilityActions.get_data_availability_data),
      tap(action => console.log({ effect: action.type, action })),
      mergeMap(props => {
        const input_data: InputData = {
          imei: props.imei,
          measurement: 'trackagri',
          fields: ['rate_of_flow'],
          start: new Date('2020-01-01T00:00:00+00:00'),
          step: '1d',
          format: OutputFormat.Measurements,
          aggregation: Aggregations.Count,
        };
        console.log({ in: 'read_data$', input_data });
        return this.measurementsApiService.fetch_device_timeseries$(input_data).pipe(
          map((data: Timeserie) => {
            const dates_with_data = _.orderBy(
              _.filter(data.measurements, (value: TrackagriFields) => value.rate_of_flow !== 0),
              ['date'],
              ['desc']
            );
            const data_availability: DataAvailability = {
              imei: input_data.imei,
              dates: _.map(dates_with_data, (value: ExtendedMeasurement) => parseISO(value.date)),
            };
            return DataAvailabilityActions.get_data_availability_data_success({ data: data_availability });
          }),
          takeUntil(this.actions$.pipe(ofType(DataAvailabilityActions.cancel_data_availability_data))),
          catchError(error => {
            console.log({ error });
            return EMPTY;
          })
        );
      })
    );
  });

  constructor(private actions$: Actions, private measurementsApiService: MeasurementsApiService) {}
}
