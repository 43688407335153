import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import * as fromDataAvailability from './data_availability.reducer';
import { DataAvailabilityEffects } from './data_availability.effects';
import { StoreModule } from '@ngrx/store';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromDataAvailability.dataAvailabilityFeatureKey, fromDataAvailability.reducer),
    EffectsModule.forFeature([DataAvailabilityEffects]),
  ],
})
export class DataAvailabilityNgrxModule {}
