import { Action, createReducer, on, createSelector, createFeatureSelector } from '@ngrx/store';
import * as Actions from './trackagri_configurations.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TrackagriConfigurations } from 'app/core/api-swagger/trackagri-api';
import { Imeis } from '../../models/Trackagri';
import * as _ from 'lodash';

export const trackagriConfigurationsFeatureKey = 'trackagri_configurations';

export interface TrackagriConfigurationsState extends EntityState<TrackagriConfigurations> {}

export interface AppState {
  [trackagriConfigurationsFeatureKey]: TrackagriConfigurationsState;
}

export function selectId(a: TrackagriConfigurations): number {
  return a.imei;
}

export function sortComparer(a: TrackagriConfigurations, b: TrackagriConfigurations): number {
  return a.imei - b.imei;
}

export const trackagriConfigurationsAdapter: EntityAdapter<TrackagriConfigurations> = createEntityAdapter<TrackagriConfigurations>({
  selectId,
  sortComparer,
});

export const initialState: TrackagriConfigurationsState = trackagriConfigurationsAdapter.getInitialState({
  ids: [],
  entities: {},
});

export const deviceReducer = createReducer(
  initialState,
  on(Actions.get_trackagri_configurations_success, (state, { data }) => ({
    ...state,
    ...trackagriConfigurationsAdapter.upsertOne(data, state),
  }))
);

export function reducer(state: TrackagriConfigurationsState | undefined, action: Action) {
  return deviceReducer(state, action);
}

// before: migrate-ngrx-13
// export const selectTrackagriConfigurationsFeature = createFeatureSelector<AppState, TrackagriConfigurationsState>(
//   trackagriConfigurationsFeatureKey
// );
export const selectTrackagriConfigurationsFeature = createFeatureSelector<TrackagriConfigurationsState>(trackagriConfigurationsFeatureKey);

export const selectTrackagriConfigurations = createSelector(selectTrackagriConfigurationsFeature, (state: TrackagriConfigurationsState) =>
  trackagriConfigurationsAdapter.getSelectors().selectEntities(state)
);

// before: migrate-ngrx-12
// export const selectTrackagriConfigurationsEntities = createSelector(
//   selectTrackagriConfigurationsFeature,
//   (state: TrackagriConfigurationsState, props: {imeis: Imeis}) => (_.pick(trackagriConfigurationsAdapter.getSelectors().selectEntities(state), props.imeis))
// );
export const selectTrackagriConfigurationsEntities = (imeis: Imeis) =>
  createSelector(selectTrackagriConfigurationsFeature, (state: TrackagriConfigurationsState) =>
    _.pick(trackagriConfigurationsAdapter.getSelectors().selectEntities(state), imeis)
  );
