import { Injectable, Optional, Injector, Inject } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ScopeGuard {
  constructor(private authService: AuthService, @Optional() @Inject('scope') private scope: string) {}

  can_create() {
    return this.authService.hasScope(this.scope);
  }

  can_update() {
    return this.authService.hasScope(this.scope);
  }

  can_delete() {
    return this.authService.hasScope(this.scope);
  }
}
