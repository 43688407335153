import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Timeserie } from 'app/core/api-swagger/measurement_api';

import { InputData, SelectedMeasurementFieldsCfg } from 'app/models';

import { MeasurementsApiRawService, MeasurementsConfigurationApiRawService } from './raw';

@Injectable({
  providedIn: 'root',
})
export class MeasurementsApiService {
  // #region -> service basics

  constructor(
    private measuremnts_api_raw_service: MeasurementsApiRawService,
    private measuremnts_configuration_api_raw_service: MeasurementsConfigurationApiRawService
  ) {}

  // #endregion

  // #region -> create

  /** */
  public create_measurement_config$(data: SelectedMeasurementFieldsCfg) {
    return this.measuremnts_configuration_api_raw_service.create_measurement_config$(data);
  }

  // #endregion

  // #region -> read

  /** */
  public fetch_alarms$(input_data: InputData): Observable<Timeserie> {
    return this.measuremnts_api_raw_service.fetch_alarms$(input_data);
  }

  /** */
  public fetch_device_timeseries$(input_data: InputData): Observable<Timeserie> {
    return this.measuremnts_api_raw_service.fetch_device_timeseries$(input_data);
  }

  /** */
  public fetch_last_data$(input_data: InputData): Observable<Timeserie> {
    return this.measuremnts_api_raw_service.fetch_last_data$(input_data);
  }

  /** */
  public fetch_last_locations$(imei: number): Observable<Timeserie> {
    return this.measuremnts_api_raw_service.fetch_last_locations$(imei);
  }

  /** */
  public fetch_locations$(input_data: InputData): Observable<Timeserie> {
    return this.measuremnts_api_raw_service.fetch_locations$(input_data);
  }

  /** */
  public fetch_measurements_config$() {
    return this.measuremnts_configuration_api_raw_service.fetch_measurements_config$();
  }

  // #endregion
}
