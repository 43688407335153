import { NgModule, LOCALE_ID, isDevMode, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, Router } from '@angular/router';
import { CalendarModule } from 'ion7-calendar';

import { TraceService, createErrorHandler } from '@sentry/angular-ivy';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule, Storage } from '@ionic/storage-angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { DatePipe, CurrencyPipe, DecimalPipe } from '@angular/common';
import { Settings } from 'app/core/services/settings';
import { environment } from '../environments/environment';
import { AuthService, ToastControllerService } from 'app/core/services/providers';
import { AuthGuard } from 'app/core/services/auth.guard';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './app.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgrxModule } from './ngrx/ngrx.module';
import { DevicesNgrxModule } from './ngrx/devices/devices.module';
import { TrackagriNgrxModule } from './ngrx/trackagri/trackagri.module';
import { ServiceBooksNgrxModule } from './ngrx/service_books/service_books.module';
import { TrackagriConfigurationsNgrxModule } from './ngrx/trackagri_configurations/trackagri_configurations.module';
import { DataAvailabilityNgrxModule } from './ngrx/data_availability/data_availability.module';
import { StoreRouterConnectingModule } from '@ngrx/router-store';

import { Configuration as ConfigurationDeviceAPI } from 'src/app/core/api-swagger/device-api/configuration';
import { Configuration as ConfigurationTrackagriAPI } from 'src/app/core/api-swagger/trackagri-api/configuration';
import { Configuration as ConfigurationIdentityServer } from 'src/app/core/api-swagger/identity-server/configuration';
import { Configuration as ConfigurationMeasurementAPI } from 'src/app/core/api-swagger/measurement_api/configuration';

import { ConfigurationParameters } from 'app/core/api-swagger/measurement_api';

import { AppInitService, initialize_app } from './core/services/misc/app-init.service';
import { SharedModule } from './widgets/widgets.module';
import { AuthInterceptor } from './core/interceptors/http-interceptor.service';

// The translate loader needs to know where to load i18n files
// in Ionic's static asset pipeline.
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', `.json?version=${environment.version}-${environment.commit_short_sha}`);
}

export function provideSettings(storage: Storage) {
  /**
   * The Settings provider takes a set of default settings for your app.
   *
   * You can add new settings options at any time. Once the settings are saved,
   * these values will not overwrite the saved values (this can be done manually if desired).
   */
  const settings = new Settings(storage, {
    version: environment.version,
  });
  settings.save();
  return settings;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    IonicModule.forRoot({
      mode: 'md',
      innerHTMLTemplatesEnabled: true,
    }),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    CalendarModule,
    NgxChartsModule,
    NgxDatatableModule,
    SharedModule,
    StoreModule.forRoot({}),
    DevicesNgrxModule,
    TrackagriNgrxModule,
    TrackagriConfigurationsNgrxModule,
    DataAvailabilityNgrxModule,
    ServiceBooksNgrxModule,
    EffectsModule.forRoot([AppEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 100, logOnly: environment.production }),
    NgrxModule,
    StoreModule.forRoot({}, {}),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
  ],
  providers: [
    { provide: ErrorHandler, useValue: createErrorHandler({ showDialog: false }) },
    { provide: TraceService, deps: [Router] },
    {
      provide: APP_INITIALIZER,
      useFactory: initialize_app,
      deps: [AppInitService, TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    DecimalPipe,
    CurrencyPipe,
    DatePipe,
    { provide: Settings, useFactory: provideSettings, deps: [Storage] },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // Keep this to enable Ionic's runtime error handling during development
    // { provide: ErrorHandler, useClass: ErrorHandler },
    { provide: LOCALE_ID, useValue: 'fr' },
    AuthGuard,
    AuthService,
    ToastControllerService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
