import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { startOfYesterday } from 'date-fns';

import { MeasurementsService, Timeserie, OutputFormat, Aggregations, Configuration, BASE_PATH } from 'app/core/api-swagger/measurement_api';

import { InputData } from 'app/models';

import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MeasurementsApiRawService extends MeasurementsService {
  // #region -> service basics

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super(httpClient, environment.measurementsApiUrl, configuration);
  }

  // #endregion

  // #region -> read

  /** */
  public fetch_last_data$(input_data: InputData): Observable<Timeserie> {
    return this.fetch_device_timeseries$(input_data);
  }

  /** */
  public fetch_last_locations$(imei: number): Observable<Timeserie> {
    const input_data = <InputData>{
      imei: imei,
      measurement: 'location',
      fields: ['gps_lat', 'gps_lng', 'gps_accuracy'],
      start: environment.production ? startOfYesterday() : new Date('2019-05-22T15:00:00+00:00'),
      end: new Date(),
    };

    return this.fetch_device_timeseries$(input_data);
  }

  /** */
  public fetch_locations$(input_data: InputData): Observable<Timeserie> {
    return this.getDeviceTimeseries(
      input_data.imei,
      'location',
      ['gps_lat', 'gps_lng', 'gps_accuracy'],
      input_data.start ? input_data.start : undefined,
      input_data.end ? input_data.end : undefined,
      input_data.step ? input_data.step : undefined,
      input_data.format ? input_data.format : undefined,
      input_data.aggregation ? input_data.aggregation : undefined
    );
  }

  /** */
  public fetch_alarms$(input_data: InputData): Observable<Timeserie> {
    return this.getDeviceTimeseries(
      input_data.imei,
      'alarm',
      ['*'],
      input_data.start ? input_data.start : undefined,
      input_data.end ? input_data.end : undefined,
      'all',
      OutputFormat.Measurements,
      Aggregations.None
    );
  }

  /** */
  public fetch_device_timeseries$(input_data: InputData): Observable<Timeserie> {
    return this.getDeviceTimeseries(
      input_data.imei,
      input_data.measurement,
      input_data.fields ? input_data.fields : ['*'],
      input_data.start ? input_data.start : undefined,
      input_data.end ? input_data.end : undefined,
      input_data.step ? input_data.step : 'all',
      input_data.format ? input_data.format : undefined,
      input_data.aggregation ? input_data.aggregation : 'none'
    );
  }

  // #endregion
}
