import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { GoogleMapsLoaderService } from '../script-injector/google-maps.service';
import { StorageEngineService } from './storage-engine.service';

/** */
export function initialize_app(appInitService: AppInitService) {
  return (): Promise<any> => appInitService.init();
}

/** */
@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  // #region -> (service basics)

  /** */
  constructor(
    private readonly _storageEngineService: StorageEngineService,
    private readonly _googleMapsLoaderService: GoogleMapsLoaderService
  ) {}

  /** */
  public init() {
    return new Promise<void>(async (resolve, reject) => {
      await this._storageEngineService.initialize();
      this._googleMapsLoaderService.initialize();

      console.info('App is ready to be used !');
      console.info(`Application running on version ${environment?.version}`);
      resolve();
    });
  }

  // #endregion
}
