import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastControllerService } from 'app/core/services/providers';
import { switchMap, catchError, map, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import * as DevicesActions from './devices.actions';
import { TranslateService } from '@ngx-translate/core';
import { DevicesApiService } from 'app/core/api/device-api/devices-api.service';

@Injectable()
export class DevicesEffects {
  read_devices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DevicesActions.read_devices),
      tap(action => console.log({ effect: action.type, action })),
      switchMap(() =>
        this.devicesDataService.fetch_devices$().pipe(
          map(data =>
            DevicesActions.read_devices_success({
              data: data.devices,
            })
          ),
          catchError(error => {
            console.log({ error });
            return EMPTY;
          })
        )
      )
    );
  });

  // update_tag_trackers$ = createEffect(() => { return this.actions$.pipe(
  //   ofType(TagTrackersActions.update_tag_tracker),
  //   tap(action => console.log({ effect: action.type, action })),
  //   switchMap((props) =>
  //     this.devicesDataService.updateTagTracker(props.id, props.data).pipe(
  //       map(() => TagTrackersActions.update_tag_tracker_success({ data: props.data })),
  //       tap(() => {
  //         const message = this.translateService.instant(marker('TAG_TRACKERS.UPDATE.UPDATED_MESSAGE'), props.data);
  //         this.toastControllerService.secondary(message);
  //       }),
  //       catchError((error) => {
  //         console.log({ error });
  //         const message = this.translateService.instant(marker('TAG_TRACKERS.UPDATE.ERROR_MESSAGE'));
  //         this.toastControllerService.danger(message);
  //         return EMPTY;
  //       })
  //     ))) }
  // );

  // create_tag_tracker$ = createEffect(() => { return this.actions$.pipe(
  //   ofType(TagTrackersActions.create_tag_tracker),
  //   tap(action => console.log({ effect: action.type, action })),
  //   switchMap((props) =>
  //     this.devicesDataService.postTagTracker(props.data).pipe(
  //       map(() => TagTrackersActions.create_tag_tracker_success({ data: props.data })),
  //       tap(response => {
  //         const message = this.translateService.instant(marker('TAG_TRACKERS.CREATE.CREATED_MESSAGE'), props.data);
  //         this.toastControllerService.secondary(message);
  //       }),
  //       catchError((error) => {
  //         const message = this.translateService.instant(marker('TAG_TRACKERS.CREATE.ERROR_MESSAGE'));
  //         this.toastControllerService.danger(message);
  //         return EMPTY;
  //       })
  //     ))) }
  // );

  // delete_tag_tracker$ = createEffect(() => { return this.actions$.pipe(
  //   ofType(TagTrackersActions.delete_tag_tracker),
  //   tap(action => console.log({ effect: action.type, action })),
  //   switchMap((props) =>
  //     this.devicesDataService.deleteTagTracker(props.imei).pipe(
  //       map(() => TagTrackersActions.delete_tag_tracker_success({ key: props.imei })),
  //       tap(() => {
  //         const message = this.translateService.instant(marker('TAG_TRACKERS.DELETE.DELETED_MESSAGE'), {imei: props.imei});
  //         this.toastControllerService.secondary(message);
  //       }),
  //       catchError((error) => {
  //         console.log({ error });
  //         return EMPTY;
  //       })
  //     ))) }
  // );

  constructor(
    private actions$: Actions,
    private devicesDataService: DevicesApiService,
    private translateService: TranslateService,
    private toastControllerService: ToastControllerService
  ) {}
}
