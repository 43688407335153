import { Injectable } from '@angular/core';

import { Loader } from '@googlemaps/js-api-loader';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsLoaderService {
  // #region -> (service basics)

  constructor() {}

  /** */
  public initialize(): void {
    const loader = new Loader({
      apiKey: environment?.google_maps?.api_key ?? null,
      version: environment?.google_maps?.version ?? 'weekly',
    });

    loader
      .importLibrary('core')
      .then(async core_library => {
        this.core_lib = core_library;
        this.geometry_lib = await loader.importLibrary('geometry');
      })
      .catch(() => {});
  }

  // #endregion

  // #region -> (libraries)

  public core_lib: google.maps.CoreLibrary = null;

  /** */
  public geometry_lib: google.maps.GeometryLibrary = null;

  // #endregion
}
