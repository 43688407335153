import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ToastControllerService {
  constructor(public toastController: ToastController) {}

  async primary(message: string, position: 'top' | 'bottom' | 'middle' = 'middle') {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: position,
      color: 'primary',
    });
    toast.present();
  }

  async secondary(message: string, position: 'top' | 'bottom' | 'middle' = 'middle') {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: position,
      color: 'secondary',
      cssClass: 'toast-center',
    });
    toast.present();
  }

  async success(message: string, position: 'top' | 'bottom' | 'middle' = 'middle') {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: position,
      color: 'success',
      cssClass: 'toast-center',
    });
    toast.present();
  }

  async danger(message: string, position: 'top' | 'bottom' | 'middle' = 'middle') {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: position,
      color: 'danger',
      cssClass: 'toast-center',
    });
    toast.present();
  }

  async warning(message: string, position: 'top' | 'bottom' | 'middle' = 'middle') {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: position,
      color: 'warning',
      cssClass: 'toast-center',
    });
    toast.present();
  }
}
