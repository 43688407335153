import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import _ from 'lodash';

import { Observable, map, tap } from 'rxjs';

import { Dynamic, BASE_PATH, PostDevices, UpdateDevice, Configuration, DevicesService, Paging } from 'app/core/api-swagger/device-api';

import { TrackagriDevice } from 'app/models/devices/trackagri-device.class';

import { MemoryCache } from '../../memory-cache';

import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DevicesApiRawService extends DevicesService {
  public readonly default_last_measurements = [
    // 'measures',
    // 'message',
    'gateway_message',
    'trackagri_AGG_fix',
    // 'location_AGG_GPS',
    // 'weight',
    // 'env',
    // 'iccid',
    // 'mvmt_status',
  ];

  // #region -> service basics

  /** */
  private devices_cache = new MemoryCache<TrackagriDevice>();

  /** */
  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super(httpClient, environment.devicesApiUrl, configuration);
  }

  // #endregion

  // #region -> create

  /** */
  public create_device$(body: PostDevices): Observable<any> {
    body.type = 'Device.Gateway.Dynamic';
    return this.postDevices(body);
  }

  // #endregion

  // #region -> read

  /** */
  public fetch_devices$(group_id?: string[]): Observable<{ devices: TrackagriDevice[]; paging: Paging }> {
    const query: any = { _cls: { $regex: '^Device.Gateway.Dynamic' } };

    if (group_id) {
      query['comment__in'] = group_id;
    }

    const query_json = JSON.stringify(query);

    return this.getDevices(null, query_json, null, ['last_data'], null, this.default_last_measurements, null, 0, -1).pipe(
      map(results => {
        const raw_devices = results.devices as Dynamic[];

        const devices = this.deserialize_devices(raw_devices);
        const sorted_devices = devices.sort((a: TrackagriDevice, b: TrackagriDevice) => (a?.name ?? '').localeCompare(b?.name ?? ''));

        console.log(sorted_devices);
        return {
          devices: sorted_devices,
          paging: results.paging,
        };
      })
    );
  }

  /** */
  public fetch_device$(imei: number): Observable<{ device: TrackagriDevice }> {
    return this.getDevice(imei, ['last_data'], null, this.default_last_measurements, null).pipe(
      map(response => {
        const raw_device = response?.device as Dynamic;
        const device = this.deserialize_device(raw_device);

        return { device };
      })
    );
  }

  // #endregion

  // #region -> update

  /** */
  public update_device$(imei: number, body: UpdateDevice): Observable<any> {
    return this.updateDevice(imei, body);
  }

  // #endregion

  // #region -> delete

  /** */
  public delete_device$(imei: number): Observable<any> {
    return this.deleteDevice(imei).pipe(
      tap(() => {
        this.devices_cache.remove(imei.toString());
      })
    );
  }

  // #endregion

  /** */
  private deserialize_device(raw_device: Dynamic): TrackagriDevice {
    let device: TrackagriDevice = null;
    const device_in_cache = this.devices_cache.get(raw_device?.imei?.toString());

    if (!_.isNil(device_in_cache)) {
      device = device_in_cache;
      device.update(raw_device);
    }

    device = new TrackagriDevice();
    device.update(raw_device);

    return device;
  }

  /** */
  private deserialize_devices(raw_devices: Dynamic[]): TrackagriDevice[] {
    return raw_devices.map(raw_device => this.deserialize_device(raw_device));
  }
}
