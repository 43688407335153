import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BASE_PATH, Configuration, ServiceBook, ServiceBooksService } from 'app/core/api-swagger/trackagri-api';

import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ServiceBooksApiRawService extends ServiceBooksService {
  // #region -> service basics

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super(httpClient, environment.trackagriApiUrl, configuration);
  }

  // #endregion

  // #region -> read

  /** */
  public fetch_service_book$(imei: number): Observable<ServiceBook> {
    return this.trackagriServiceBooksImeiGet(imei);
  }

  // #endregion

  // #region -> update

  /** */
  public update_service_book$(body: ServiceBook, imei: number): Observable<ServiceBook> {
    return this.trackagriServiceBooksImeiPut(body, imei);
  }

  // #endregion
}
