import { createAction, props } from '@ngrx/store';
import { ServiceBook } from 'app/core/api-swagger/trackagri-api';

export enum serviceBooksActionTypes {
  UpsertServiceBooks = '[ServiceBooks] CREATE',
  UpsertServiceBooksSuccess = '[ServiceBooks] CREATE => 201',
  UpsertServiceBooksFail = '[ServiceBooks] CREATE => 4xx',
  ReadServiceBooks = '[ServiceBooks] READ',
  ReadServiceBooksSuccess = '[ServiceBooks] READ => 200',
  ReadServiceBooksFail = '[ServiceBooks] READ => 4xx',
}

export const upsert_service_books = createAction(serviceBooksActionTypes.UpsertServiceBooks, props<{ data: ServiceBook }>());
export const upsert_service_books_success = createAction(serviceBooksActionTypes.UpsertServiceBooksSuccess, props<{ data: ServiceBook }>());
export const upsert_service_books_fail = createAction(serviceBooksActionTypes.UpsertServiceBooksFail, props<{ data: ServiceBook }>());
export const read_service_books = createAction(serviceBooksActionTypes.ReadServiceBooks, props<{ imei: number }>());
export const read_service_books_success = createAction(serviceBooksActionTypes.ReadServiceBooksSuccess, props<{ data: ServiceBook }>());
export const read_service_books_fail = createAction(serviceBooksActionTypes.ReadServiceBooksFail, props<{ data: ServiceBook }>());
