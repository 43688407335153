/**
 * device-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: release-v2024-01-r0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DeletedDeviceReturn } from '../model/deletedDeviceReturn';
import { Device } from '../model/device';
import { DevicesStatsReturn } from '../model/devicesStatsReturn';
import { GetDeviceReturn } from '../model/getDeviceReturn';
import { GetDevicesListReturn } from '../model/getDevicesListReturn';
import { OrderedError } from '../model/orderedError';
import { PostDevices } from '../model/postDevices';
import { UpdateDevice } from '../model/updateDevice';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DevicesService {

    protected basePath = 'http://0.0.0.0:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Delete a device
     * 
     * @param imei 
     * @param force Force device deletion event if device serialization is not possible.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected deleteDevice(imei: number, force?: boolean, observe?: 'body', reportProgress?: boolean): Observable<DeletedDeviceReturn>;
    protected deleteDevice(imei: number, force?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeletedDeviceReturn>>;
    protected deleteDevice(imei: number, force?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeletedDeviceReturn>>;
    protected deleteDevice(imei: number, force?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling deleteDevice.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (force !== undefined && force !== null) {
            queryParameters = queryParameters.set('force', <any>force);
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<DeletedDeviceReturn>(`${this.basePath}/devices/${encodeURIComponent(String(imei))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a device
     * 
     * @param imei 
     * @param include List of optional device fields to include
     * @param exclude List of device fields to exclude
     * @param last_measurements List of last measurements aggregation to get for the device
     * @param last_measurements_detail List of last measurements detailed list to load with for the device
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getDevice(imei: number, include?: Array<string>, exclude?: Array<string>, last_measurements?: Array<string>, last_measurements_detail?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<GetDeviceReturn>;
    protected getDevice(imei: number, include?: Array<string>, exclude?: Array<string>, last_measurements?: Array<string>, last_measurements_detail?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetDeviceReturn>>;
    protected getDevice(imei: number, include?: Array<string>, exclude?: Array<string>, last_measurements?: Array<string>, last_measurements_detail?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetDeviceReturn>>;
    protected getDevice(imei: number, include?: Array<string>, exclude?: Array<string>, last_measurements?: Array<string>, last_measurements_detail?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling getDevice.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (include) {
            include.forEach((element) => {
                queryParameters = queryParameters.append('include', <any>element);
            })
        }
        if (exclude) {
            exclude.forEach((element) => {
                queryParameters = queryParameters.append('exclude', <any>element);
            })
        }
        if (last_measurements) {
            last_measurements.forEach((element) => {
                queryParameters = queryParameters.append('last_measurements', <any>element);
            })
        }
        if (last_measurements_detail) {
            last_measurements_detail.forEach((element) => {
                queryParameters = queryParameters.append('last_measurements_detail', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetDeviceReturn>(`${this.basePath}/devices/${encodeURIComponent(String(imei))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List devices
     * 
     * @param device_imeis Get the given devices.
     * @param query Query to filter devices (stringified from json). Note: fields ending with &#39;_date&#39; will be parsed to date (isoformat).  Special field &#x60;_order&#x60;: can be use to filters devices based on current order. 
     * @param sort List of fields used to sort the returned list of devices.
     * @param include List of optional device fields to include
     * @param exclude List of device fields to exclude
     * @param last_measurements List of last measurements aggregation to get for the device
     * @param last_measurements_detail List of last measurements detailed list to load with for the device
     * @param offset Paging offset.
     * @param limit Max number of devices to return.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getDevices(device_imeis?: Array<number>, query?: string, sort?: Array<string>, include?: Array<string>, exclude?: Array<string>, last_measurements?: Array<string>, last_measurements_detail?: Array<string>, offset?: number, limit?: number, observe?: 'body', reportProgress?: boolean): Observable<GetDevicesListReturn>;
    protected getDevices(device_imeis?: Array<number>, query?: string, sort?: Array<string>, include?: Array<string>, exclude?: Array<string>, last_measurements?: Array<string>, last_measurements_detail?: Array<string>, offset?: number, limit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetDevicesListReturn>>;
    protected getDevices(device_imeis?: Array<number>, query?: string, sort?: Array<string>, include?: Array<string>, exclude?: Array<string>, last_measurements?: Array<string>, last_measurements_detail?: Array<string>, offset?: number, limit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetDevicesListReturn>>;
    protected getDevices(device_imeis?: Array<number>, query?: string, sort?: Array<string>, include?: Array<string>, exclude?: Array<string>, last_measurements?: Array<string>, last_measurements_detail?: Array<string>, offset?: number, limit?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {










        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (device_imeis) {
            device_imeis.forEach((element) => {
                queryParameters = queryParameters.append('device_imeis', <any>element);
            })
        }
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }
        if (include) {
            include.forEach((element) => {
                queryParameters = queryParameters.append('include', <any>element);
            })
        }
        if (exclude) {
            exclude.forEach((element) => {
                queryParameters = queryParameters.append('exclude', <any>element);
            })
        }
        if (last_measurements) {
            last_measurements.forEach((element) => {
                queryParameters = queryParameters.append('last_measurements', <any>element);
            })
        }
        if (last_measurements_detail) {
            last_measurements_detail.forEach((element) => {
                queryParameters = queryParameters.append('last_measurements_detail', <any>element);
            })
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetDevicesListReturn>(`${this.basePath}/devices`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Count devices
     * 
     * @param query Query to filter devices (stringified from json). Note: fields ending with &#39;_date&#39; will be parsed to date (isoformat).  Special field &#x60;_order&#x60;: can be use to filters devices based on current order. 
     * @param groupby Json to group query
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getDevicesStats(query?: string, groupby?: string, observe?: 'body', reportProgress?: boolean): Observable<DevicesStatsReturn>;
    protected getDevicesStats(query?: string, groupby?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DevicesStatsReturn>>;
    protected getDevicesStats(query?: string, groupby?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DevicesStatsReturn>>;
    protected getDevicesStats(query?: string, groupby?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (groupby !== undefined && groupby !== null) {
            queryParameters = queryParameters.set('groupby', <any>groupby);
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<DevicesStatsReturn>(`${this.basePath}/devices/_stats`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new device
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected postDevices(body: PostDevices, observe?: 'body', reportProgress?: boolean): Observable<GetDeviceReturn>;
    protected postDevices(body: PostDevices, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetDeviceReturn>>;
    protected postDevices(body: PostDevices, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetDeviceReturn>>;
    protected postDevices(body: PostDevices, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling postDevices.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GetDeviceReturn>(`${this.basePath}/devices`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a device
     * 
     * @param imei 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected updateDevice(imei: number, body: UpdateDevice, observe?: 'body', reportProgress?: boolean): Observable<Device>;
    protected updateDevice(imei: number, body: UpdateDevice, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Device>>;
    protected updateDevice(imei: number, body: UpdateDevice, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Device>>;
    protected updateDevice(imei: number, body: UpdateDevice, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling updateDevice.');
        }

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateDevice.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Device>(`${this.basePath}/devices/${encodeURIComponent(String(imei))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
