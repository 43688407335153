import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { init, browserTracingIntegration, inboundFiltersIntegration, captureConsoleIntegration } from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';

import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (environment?.sentry?.dsn) {
  init({
    dsn: environment.sentry.dsn,
    environment: environment?.environment,
    release: `trackagri-app@${environment.sentry.release_version}`,

    sendClientReports: false,
    autoSessionTracking: false,
    attachStacktrace: true,

    integrations: [
      browserTracingIntegration({
        tracePropagationTargets: ['localhost', /^\//],
      }),
      captureConsoleIntegration({
        levels: ['error'],
      }),
      inboundFiltersIntegration({
        // ignoreErrors: ['Non-Error exception captured', 'ChunkLoadError', 'FORBIDDEN', 'Unauthorized', 'Unauthorized - invalid_token'],
        denyUrls: [/clarity\.ms/i, /google\.com/i, /google-analytics\.com/i, /extensions\//i, /^chrome:\/\//i],
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));
