import packageJson from '../../package.json';

/** */
export interface IEnvironment {
  /**
   * Name of the application  (from package.json)
   *
   */
  name: string;

  /**
   * Version of the application (from package.json)
   *
   */
  version: `${number}.${number}.${number}`;

  /** */
  commit_short_sha: string;

  /**
   * Name of the environment in which the application is runned.
   * @deprecated
   */
  env: 'Development' | 'Preprod' | 'Production';

  /**
   * Name of the environment in which the application is runned.
   *
   * @note Do not change the name, because it is used for sentry
   */
  environment: 'dev' | 'prod-preprod' | 'prod';

  /**
   * Is application in production mode ?
   */
  production: boolean;

  /** */
  devicesApiUrl: string;

  /** */
  measurementsApiUrl: string;

  /** */
  identityServerUrl: string;

  /** */
  trackagriApiUrl: string;

  /** */
  google_maps: {
    /** */
    api_key: string;

    /** */
    version: string;
  };

  /**
   * Sentry configuration
   */
  sentry: {
    /**
     * DSN of sentry
     */
    dsn: string;

    /** */
    release_version: string;
  };
}

/** */
export const default_environment: IEnvironment = {
  name: packageJson.name,
  version: packageJson.version as any,
  commit_short_sha: '48c8913d',
  env: null,
  environment: 'dev',
  production: false,
  devicesApiUrl: null,
  identityServerUrl: null,
  measurementsApiUrl: null,
  trackagriApiUrl: null,

  google_maps: {
    api_key: null,
    version: '3.58.2',
  },

  sentry: {
    dsn: null,
    release_version: '2.0.8',
  },
};
