/**
 * Measurement API
 * This document is the reference documentation of measurement api 
 *
 * OpenAPI spec version: NEXT
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type OutputFormat = 'points' | 'measurements';

export const OutputFormat = {
    Points: 'points' as OutputFormat,
    Measurements: 'measurements' as OutputFormat
};