import { Injectable } from '@angular/core';

import { GroupsApiRawService, BinaryFilesApiRawService, ServiceBooksApiRawService, TrackagriConfigurationsApiRawService } from './raw';
import {
  InputCreateBinaryFile,
  InputCreateGroup,
  InputCreateSubgroup,
  InputUpdateBinaryFile,
  InputUpdateGroup,
  InputUpdateSubgroup,
  ServiceBook,
  TrackagriConfiguration,
} from 'app/core/api-swagger/trackagri-api';

@Injectable({
  providedIn: 'root',
})
export class TrackagriApiService {
  // #region -> service basics

  constructor(
    private groups_api_raw_service: GroupsApiRawService,
    private binary_files_api_raw_service: BinaryFilesApiRawService,
    private service_books_api_raw_service: ServiceBooksApiRawService,
    private trackagri_configurations_api_raw_service: TrackagriConfigurationsApiRawService
  ) {}

  // #endregion

  // #region -> create

  /** */
  public create_binary_file$(input: InputCreateBinaryFile) {
    return this.binary_files_api_raw_service.create_binary_file$(input);
  }

  /** */
  public create_groups$(input: InputCreateGroup) {
    return this.groups_api_raw_service.create_groups$(input);
  }

  /** */
  public create_subgroup$(subgroup: InputCreateSubgroup, group_id: string) {
    return this.groups_api_raw_service.create_subgroup$(subgroup, group_id);
  }

  // #endregion

  // #region -> read

  /** */
  public fetch_binary_file$(id: string) {
    return this.binary_files_api_raw_service.fetch_binary_file$(id);
  }

  /** */
  public fetch_binary_files$() {
    return this.binary_files_api_raw_service.fetch_binary_files$();
  }

  /** */
  public fetch_configurations$(imei: number, date_from: string, date_to: string) {
    return this.trackagri_configurations_api_raw_service.fetch_configurations$(imei, date_from, date_to);
  }

  /** */
  public fetch_group$(group_id: string) {
    return this.groups_api_raw_service.fetch_group$(group_id);
  }

  /** */
  public fetch_groups$() {
    return this.groups_api_raw_service.fetch_groups$();
  }

  /** */
  public fetch_service_book$(imei: number) {
    return this.service_books_api_raw_service.fetch_service_book$(imei);
  }

  /** */
  public fetch_subgroup$(subgroup_id: string) {
    return this.groups_api_raw_service.fetch_subgroup$(subgroup_id);
  }

  // #endregion

  // #region -> update

  /** */
  public update_binary_file$(input: InputUpdateBinaryFile, id: string) {
    return this.binary_files_api_raw_service.update_binary_file$(input, id);
  }

  /** */
  public update_configuration$(body: TrackagriConfiguration, imei: number) {
    return this.trackagri_configurations_api_raw_service.update_configuration$(body, imei);
  }

  /** */
  public update_groups$(input: InputUpdateGroup, group_id: string) {
    return this.groups_api_raw_service.update_groups$(input, group_id);
  }

  /** */
  public update_service_book$(body: ServiceBook, imei: number) {
    return this.service_books_api_raw_service.update_service_book$(body, imei);
  }

  /** */
  public update_subgroups$(input: InputUpdateSubgroup, subgroup_id: string) {
    return this.groups_api_raw_service.update_subgroups$(input, subgroup_id);
  }

  // #endregion

  // #region -> delete

  /** */
  public delete_binary_file$(id: string) {
    return this.binary_files_api_raw_service.delete_binary_file$(id);
  }

  /** */
  public delete_group$(group_id: string) {
    return this.groups_api_raw_service.delete_group$(group_id);
  }

  /** */
  public delete_subgroup$(subgroup_id: string) {
    return this.groups_api_raw_service.delete_subgroup$(subgroup_id);
  }

  // #endregion
}
