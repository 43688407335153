import { Action, createReducer, on, createSelector, createFeatureSelector } from '@ngrx/store';
import * as Actions from './service_books.actions';
import { ServiceBook } from 'app/core/api-swagger/trackagri-api';

export const ServiceBooksFeatureKey = 'service_books';

export interface ServiceBooksState {
  downloaded: boolean;
  service_book: ServiceBook;
}

export interface AppState {
  [ServiceBooksFeatureKey]: ServiceBooksState;
}

export const initialState: ServiceBooksState = {
  downloaded: false,
  service_book: undefined,
};

export const deviceReducer = createReducer(
  initialState,
  on(Actions.upsert_service_books, (state, { data }): ServiceBooksState => ({ ...state, service_book: undefined, downloaded: false })),
  on(Actions.upsert_service_books_success, (state, { data }): ServiceBooksState => ({ ...state, service_book: data, downloaded: true })),
  on(Actions.upsert_service_books_fail, (state, { data }): ServiceBooksState => ({ ...state, service_book: undefined, downloaded: true })),

  on(Actions.read_service_books, (state): ServiceBooksState & { cfg: any } => ({ ...state, cfg: undefined, downloaded: false })),
  on(Actions.read_service_books_success, (state, { data }): ServiceBooksState => ({ ...state, service_book: data, downloaded: true })),
  on(Actions.read_service_books_fail, (state, { data }): ServiceBooksState => ({ ...state, service_book: undefined, downloaded: true }))
);

export function reducer(state: ServiceBooksState | undefined, action: Action) {
  return deviceReducer(state, action);
}

// eslint-disable-next-line max-len
// before: migrate-ngrx-13
// export const selectServiceBooksFeature = createFeatureSelector<AppState, ServiceBooksState>(ServiceBooksFeatureKey);
export const selectServiceBooksFeature = createFeatureSelector<ServiceBooksState>(ServiceBooksFeatureKey);

export const selectServiceBooks = createSelector(selectServiceBooksFeature, (state: ServiceBooksState) => state.service_book);

export const selectServiceBooksDownloaded = createSelector(selectServiceBooksFeature, (state: ServiceBooksState) => state.downloaded);
