import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';

import { BASE_PATH, Configuration, OrdersService, UpdateOrder } from 'app/core/api-swagger/device-api';

import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrdersApiRawService extends OrdersService {
  // #region -> (service basics)

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super(httpClient, environment.trackagriApiUrl, configuration);
  }

  // #endregion

  // #region -> (update methods)

  /** */
  public set_order$(imei: number, value: UpdateOrder): Observable<any> {
    return this.putOrder(imei, value);
  }

  // #endregion
}
