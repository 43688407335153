import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';

import { BehaviorSubject, Observable, forkJoin, from, map, of, shareReplay, switchMap, take, tap } from 'rxjs';

import { isNil } from 'lodash';

import { StorageEngineService } from './misc/storage-engine.service';

import { IdentityServerApiService } from '../api';
import { User } from 'app/models/user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // #region -> service basics

  /** */
  private readonly STORAGE_AUTH_USER = 'auth.user';

  /** */
  private readonly STORAGE_AUTH_EXPIRES_AT = 'auth.expires_at';

  /** */
  private readonly STORAGE_AUTH_ACCESS_TOKEN = 'auth.access_token';

  /** */
  constructor(
    private navCtrl: NavController,
    private _storage: StorageEngineService,
    private _identityServerApiService: IdentityServerApiService
  ) {}

  // #endregion

  // #region -> credentials

  /** */
  private _access_token$ = new BehaviorSubject<string>(null);

  /** */
  public access_token$$ = this._access_token$.asObservable().pipe(shareReplay({ refCount: true, bufferSize: 1 }));

  /** */
  public set access_token(access_token: string) {
    this._storage.set(this.STORAGE_AUTH_ACCESS_TOKEN, access_token);

    this._access_token$.next(access_token);
  }

  /** */
  public get access_token(): string {
    return this._access_token$.getValue();
  }

  // #endregion

  // #region -> authenticated user

  /** */
  private _real_user$ = new BehaviorSubject<User>(null);

  /** */
  public real_user$$ = this._real_user$.asObservable().pipe(
    tap(real_user => console.log({ real_user })),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  /** */
  public get user(): User {
    return this._real_user$.getValue();
  }

  /** */
  public set user(user: User) {
    this._real_user$.next(user);
  }

  // #endregion

  /** */
  login(access_token: string, expires_at: Number, user: User) {
    this._storage.set(this.STORAGE_AUTH_USER, user?.id);
    this._storage.set(this.STORAGE_AUTH_EXPIRES_AT, expires_at);

    this.access_token = access_token;

    this._identityServerApiService.fetch_user$(user?.id).subscribe({
      next: user => {
        this._real_user$.next(user);
      },
    });
  }

  /** */
  logout() {
    console.log({ storage: this._storage });

    forkJoin([
      from(this._storage.remove(this.STORAGE_AUTH_USER)),
      from(this._storage.remove(this.STORAGE_AUTH_EXPIRES_AT)),
      from(this._storage.remove(this.STORAGE_AUTH_ACCESS_TOKEN)),
    ]).subscribe({
      complete: () => {
        this._real_user$.next(null);
      },
    });
  }

  /** */
  public isAuthenticated(): Observable<boolean> {
    return forkJoin([
      from(this._storage.get(this.STORAGE_AUTH_ACCESS_TOKEN)),
      from(this._storage.get(this.STORAGE_AUTH_EXPIRES_AT)),
      from(this._storage.get(this.STORAGE_AUTH_USER)),
    ]).pipe(
      switchMap(([access_token, expires_at, user_id]) => {
        console.log({ access_token, expires_at, user_id, now: Date.now() < expires_at });

        if (!isNil(access_token) && !isNil(expires_at)) {
          if (Date.now() < expires_at) {
            this.access_token = access_token;

            return this._identityServerApiService.fetch_user$(user_id).pipe(
              map(user => {
                this._real_user$.next(user);
                return true;
              })
            );
          }
        }

        setTimeout(() => {
          this.navCtrl.navigateRoot('/login');
        });

        return of(false);
      }),
      take(1)
    );
  }

  public hasScope(scope: string) {
    const user = this.user;

    const has_scope =
      user &&
      user.scopes &&
      (user.scopes.findIndex((value: any) => value === 'admin') > -1 || user.scopes.findIndex((value: any) => value === scope) > -1);

    return has_scope;
  }

}
