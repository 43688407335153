import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastControllerService } from 'app/core/services/providers';
import { switchMap, catchError, map, tap, mergeMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import * as TrackagriConfigurationsActions from './trackagri_configurations.actions';
import { TranslateService } from '@ngx-translate/core';
import { TrackagriApiService } from 'app/core/api';

import { marker } from '@colsen1991/ngx-translate-extract-marker';

@Injectable()
export class TrackagriConfigurationsEffects {
  get_trackagri_configurations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TrackagriConfigurationsActions.get_trackagri_configurations),
      tap(action => console.log({ effect: action.type, action })),
      mergeMap(props =>
        this.trackagriApiService.fetch_configurations$(props.imei, props.dates.date_from, props.dates.date_to).pipe(
          tap(data => console.log({ getTrackagriConfiguration: 'success', data })),
          map(data => TrackagriConfigurationsActions.get_trackagri_configurations_success({ data: data })),
          catchError(error => {
            console.log({ error });
            return EMPTY;
            // return of(TrackagriConfigurationsActions.get_trackagri_configurations_fail({ data: props }));
          })
        )
      )
    );
  });

  upsert_trackagri_configurations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TrackagriConfigurationsActions.upsert_trackagri_configurations),
      tap(action => console.log({ effect: action.type, action })),
      switchMap(props =>
        this.trackagriApiService.update_configuration$(props.data, props.data.imei).pipe(
          map(() => TrackagriConfigurationsActions.upsert_trackagri_configurations_success({ data: props.data })),
          tap(() => {
            const message = this.translateService.instant(marker('TRACKAGRI.UPSERT.UPSERTED_MESSAGE'), props.data);
            this.toastControllerService.success(message);
          }),
          catchError(error => {
            console.log({ error });
            const message = this.translateService.instant(marker('TRACKAGRI.UPSERT.ERROR_MESSAGE'));
            this.toastControllerService.danger(message);
            return EMPTY;
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private trackagriApiService: TrackagriApiService,
    private translateService: TranslateService,
    private toastControllerService: ToastControllerService
  ) {}
}
