import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromServiceBooks from './service_books.reducer';
import { ServiceBooksEffects } from './service_books.effects';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromServiceBooks.ServiceBooksFeatureKey, fromServiceBooks.reducer),
    EffectsModule.forFeature([ServiceBooksEffects]),
  ],
})
export class ServiceBooksNgrxModule {
  constructor() {}
}
