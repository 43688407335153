import { createAction, props } from '@ngrx/store';
import { TrackagriConfiguration, TrackagriConfigurations } from 'app/core/api-swagger/trackagri-api';
import { Dates } from '../../models/Trackagri';

export enum trackagriConfigurationsActionTypes {
  UpsertTrackagriConfigurations = '[TrackagriConfigurations] CREATE',
  UpsertTrackagriConfigurationsSuccess = '[TrackagriConfigurations] CREATE => 201',
  UpsertTrackagriConfigurationsFail = '[TrackagriConfigurations] CREATE => 4xx',
  GetTrackagriConfigurations = '[TrackagriConfigurations] GET',
  GetTrackagriConfigurationsSuccess = '[TrackagriConfigurations] GET => 200',
  GetTrackagriConfigurationsFail = '[TrackagriConfigurations] GET => 4xx',
}

export const upsert_trackagri_configurations = createAction(
  trackagriConfigurationsActionTypes.UpsertTrackagriConfigurations,
  props<{ data: TrackagriConfiguration }>()
);
export const upsert_trackagri_configurations_success = createAction(
  trackagriConfigurationsActionTypes.UpsertTrackagriConfigurationsSuccess,
  props<{ data: TrackagriConfiguration }>()
);
export const upsert_trackagri_configurations_fail = createAction(
  trackagriConfigurationsActionTypes.UpsertTrackagriConfigurationsFail,
  props<{ data: TrackagriConfiguration }>()
);
export const get_trackagri_configurations = createAction(
  trackagriConfigurationsActionTypes.GetTrackagriConfigurations,
  props<{ imei: number; dates: Dates }>()
);
export const get_trackagri_configurations_success = createAction(
  trackagriConfigurationsActionTypes.GetTrackagriConfigurationsSuccess,
  props<{ data: TrackagriConfigurations }>()
);
export const get_trackagri_configurations_fail = createAction(
  trackagriConfigurationsActionTypes.GetTrackagriConfigurationsFail,
  props<{ data: TrackagriConfigurations }>()
);
