import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';

import { OverlayEventDetail } from '@ionic/core';
import { Platform, ModalController } from '@ionic/angular';

import { addIcons } from 'ionicons';

import { TranslateService } from '@ngx-translate/core';
import { timeFormatDefaultLocale } from 'd3-time-format';

import moment from 'moment';
import 'moment/locale/fr';

import { User, InputChangePassword } from 'app/core/api-swagger/identity-server';
import { AuthService, ToastControllerService } from 'app/core/services/providers';

import { environment } from '../environments/environment';
import { StorageEngineService } from './core/services/misc/storage-engine.service';
import { IdentityServerApiService } from './core/api';
import { map, of, shareReplay } from 'rxjs';

import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { UsersChangePasswordPage } from './widgets/users-change-password/users-change-password.page';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  production: any;

  // #region -> component basics

  constructor(
    private router: Router,
    private platform: Platform,
    private storage: StorageEngineService,
    private translate: TranslateService,
    private authService: AuthService,
    private modalController: ModalController,
    private usersDataService: IdentityServerApiService,
    private translateService: TranslateService,
    private toastControllerService: ToastControllerService
  ) {
    this.initializeApp();
  }

  ngOnInit(): void {
    this.router.navigate(['root']);

    addIcons({
      'sync-off': 'assets/ionic-icons/sync-off.svg',
      'content-copy': 'assets/ionic-icons/content-copy.svg',
      'package-variant-closed': 'assets/ionic-icons/package-variant-closed.svg',
    });
  }

  // #endregion

  // #region -> menus

  /** */
  public menu_content_entries: any[] = [];

  /** */
  public menu_footer_entries = [
    {
      title: marker('MENU.ENTRY.LOGOUT'),
      url: '/login',
      icon: 'log-out',
      click: () => this.authService.logout(),
      color: 'danger',
    },
  ];

  // #endregion

  // #region -> version

  /** */
  public version$$ = of(environment.version).pipe(
    map(version => {
      switch (environment.env) {
        case 'Development': {
          return `${version}-dev`;
        }

        case 'Preprod': {
          return `${version}-preprod-${environment?.commit_short_sha}`;
        }

        case 'Production':
        default: {
          return version;
        }
      }
    }),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  // #endregion

  // #region -> user

  /** */
  public real_user$$ = this.authService.real_user$$;

  // #endregion

  initializeApp() {
    this.production = environment.production && environment.env === 'Production';

    this.platform.ready().then(() => {
      this.initTranslate();

      this.storage.set('version', environment.version);

      this.menu_content_entries = [
        // {
        //   title: 'MENU.ENTRY.HOME',
        //   url: '/root',
        //   guard: () => true,
        //   icon: 'home',
        //   color: 'primary',
        // },
        {
          title: marker('MENU.ENTRY.Clients list'),
          url: '/root',
          guard: () => !this.authService?.user?.hasGroup() && !this.authService?.user?.hasSubgroup(),
          icon: 'list',
          color: 'primary',
        },
        {
          title: marker('MENU.ENTRY.Vehicle fleets'),
          url: '/root',
          guard: () => this.authService?.user?.hasGroup() && !this.authService?.user?.hasSubgroup(),
          icon: 'warehouse',
          color: 'primary',
        },
        {
          title: marker('MENU.ENTRY.Vehicles'),
          url: '/root',
          guard: () => this.authService?.user?.hasGroup() && this.authService?.user?.hasSubgroup(),
          icon: 'list',
          color: 'primary',
        },
        {
          title: marker('MENU.ENTRY.DEVICES'),
          url: '/devices',
          guard: () =>
            this.authService.hasScope('devices:rw') ||
            this.authService.hasScope('configurations:rw') ||
            this.authService.hasScope('maintenances:rw') ||
            this.authService.hasScope('admin'),
          icon: 'albums',
          color: 'primary',
        },
        {
          title: marker('MENU.ENTRY.USERS'),
          url: '/users',
          guard: () => this.authService.hasScope('users:rw') || this.authService.hasScope('admin'),
          icon: 'people',
          color: 'primary',
        },
        {
          title: marker('MENU.ENTRY.ROLES'),
          url: '/roles',
          guard: () => this.authService.hasScope('roles:rw') || this.authService.hasScope('admin'),
          icon: 'key',
          color: 'primary',
        },
      ];
    });
  }

  initTranslate() {
    registerLocaleData(localeFr, 'fr');

    // Set the default language for translation strings, and the current language.
    this.translate.setDefaultLang('fr');
    this.translate.use('fr');

    try {
      timeFormatDefaultLocale({
        dateTime: '%A %e %B %Y à %X',
        date: '%d/%m/%Y',
        time: '%H:%M:%S',
        periods: ['AM', 'PM'],
        days: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
        shortDays: ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'],
        months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
        shortMonths: ['janv.', 'févr.', 'mars', 'avr.', 'mai', 'juin', 'juil.', 'août', 'sept.', 'oct.', 'nov.', 'déc.'],
      });
    } catch (error) {
      console.warn({ in: 'timeFormatDefaultLocale', error });
    }

    moment.locale('fr');
  }

  async handleChangePassword() {
    const selected_user = this.authService.user;
    const modal = await this.modalController.create({
      component: UsersChangePasswordPage,
      componentProps: {
        user: selected_user,
      },
    });

    await modal.present();

    const { data }: OverlayEventDetail<InputChangePassword> = await modal.onDidDismiss();

    if (data) {
      this.usersDataService.update_user_password$(data.id, data).subscribe(
        user => {
          const message = this.translateService.instant(marker('SETTINGS.CHANGE_PASSWORD.UPDATED_MESSAGE'), this.authService.user);
          this.toastControllerService.success(message);
        },
        error => {
          const message = this.translateService.instant(marker('USERS.CHANGE_PASSWORD.ERROR_MESSAGE'));
          this.toastControllerService.danger(message);
        }
      );
    }
  }
}
