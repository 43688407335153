import { filter, has, isNil, values } from 'lodash';
import { Observable, of, Subject } from 'rxjs';

/** */
export class MemoryCache<ObjectType> {
  /** */
  public cache: Record<string, ObjectType> = {};

  /** */
  private _cache$ = new Subject<Record<string, ObjectType>>();

  /** */
  public cache$ = this._cache$.asObservable();

  /** */
  constructor() {}

  /** */
  public has(key: string): boolean {
    return has(this.cache, key);
  }

  /** */
  public get(key: string): ObjectType {
    if (this.has(key)) {
      return this.cache[key];
    }

    return undefined;
  }

  /** */
  public getType(type: number | string): ObjectType[] {
    return filter(this.cache, (obj: any) => obj.type === type);
  }

  /** */
  public add(key: string, object: ObjectType): void {
    this.cache[key] = object;
    this._cache$.next(this.cache);
  }

  /** */
  public remove(key: string): void {
    if (this.has(key)) {
      if (this.cache[key].hasOwnProperty('preDestroy')) {
        (this.cache as any)[key].preDestroy();
      }

      delete this.cache[key];
      this._cache$.next(this.cache);
    }
  }

  /** */
  public razCache(): void {
    values(this.cache).map((obj: any) => {
      if (obj.hasOwnProperty('preDestroy')) {
        obj.preDestroy();
      }
    });

    this.cache = {};
    this._cache$.next(this.cache);
  }
}
