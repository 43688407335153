import { Injectable } from '@angular/core';

import { saveAs } from 'file-saver';

import { TranslateService } from '@ngx-translate/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

@Injectable({
  providedIn: 'root',
})
export class CsvDataService {
  constructor(private translate: TranslateService) {}

  i18n = [
    marker('EXPORT.SECTION_GENERAL'),
    marker('EXPORT.SECTION_MVT'),
    marker('EXPORT.SECTION_SPRAYER'),
    marker('EXPORT.SECTION_TIME'),
    marker('EXPORT.SECTION_TOOL'),
  ];

  saveAsCsv(filename: string, csvContent: string) {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    saveAs(blob, filename);
  }

  exportToCsv(filename: string, rows: object[], needTranslate: boolean = false, header: string = undefined) {
    if (!rows || !rows.length) {
      return;
    }

    const csvContent = this.dataToCsv(rows, needTranslate, header);

    this.saveAsCsv(filename, csvContent);
  }

  dataToCsv(rows: object[], needTranslate: boolean = false, header: string = undefined): string {
    const separator = ';';
    const keys = Object.keys(!rows || !rows.length ? [] : rows[0]);
    const columns = needTranslate ? keys.map(value => this.translate.instant('EXPORT.' + value.toUpperCase())) : [...keys];

    const csvContent =
      (header || '') +
      columns.join(separator) +
      '\n' +
      rows
        .map((row: any) => {
          return keys
            .map(k => {
              let cell = row[k] === null || row[k] === undefined ? '' : row[k];

              cell = cell instanceof Date ? cell.toLocaleString() : cell.toString().replace(/"/g, '""').replace(/\./g, ',');

              if (cell.search(/("|;|\n)/g) >= 0) {
                cell = `"${cell}"`;
              }

              return cell;
            })
            .join(separator);
        })
        .join('\n');

    return csvContent;
  }
}
