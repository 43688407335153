import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevicesNgrxModule } from './devices/devices.module';
import { TrackagriNgrxModule } from './trackagri/trackagri.module';
import { TrackagriConfigurationsNgrxModule } from './trackagri_configurations/trackagri_configurations.module';
import { DataAvailabilityNgrxModule } from './data_availability/data_availability.module';
import { ServiceBooksNgrxModule } from './service_books/service_books.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DevicesNgrxModule,
    TrackagriNgrxModule,
    TrackagriConfigurationsNgrxModule,
    DataAvailabilityNgrxModule,
    ServiceBooksNgrxModule,
  ],
})
export class NgrxModule {
  constructor() {}
}
