/**
 * device-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: release-v2024-01-r0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { GetOrder } from '../model/getOrder';
import { OrderedError } from '../model/orderedError';
import { UpdateOrder } from '../model/updateOrder';
import { UpdateOrderResponse } from '../model/updateOrderResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class OrdersService {

    protected basePath = 'http://0.0.0.0:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Cancel/RAZ an order
     * 
     * @param imei 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected deleteOrder(imei: number, observe?: 'body', reportProgress?: boolean): Observable<UpdateOrderResponse>;
    protected deleteOrder(imei: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UpdateOrderResponse>>;
    protected deleteOrder(imei: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UpdateOrderResponse>>;
    protected deleteOrder(imei: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling deleteOrder.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<UpdateOrderResponse>(`${this.basePath}/devices/${encodeURIComponent(String(imei))}/order`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get pending order
     * 
     * @param imei 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getOrder(imei: number, observe?: 'body', reportProgress?: boolean): Observable<GetOrder>;
    protected getOrder(imei: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetOrder>>;
    protected getOrder(imei: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetOrder>>;
    protected getOrder(imei: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling getOrder.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetOrder>(`${this.basePath}/devices/${encodeURIComponent(String(imei))}/order`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an order for a device
     * 
     * @param imei 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected putOrder(imei: number, body: UpdateOrder, observe?: 'body', reportProgress?: boolean): Observable<UpdateOrderResponse>;
    protected putOrder(imei: number, body: UpdateOrder, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UpdateOrderResponse>>;
    protected putOrder(imei: number, body: UpdateOrder, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UpdateOrderResponse>>;
    protected putOrder(imei: number, body: UpdateOrder, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling putOrder.');
        }

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling putOrder.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<UpdateOrderResponse>(`${this.basePath}/devices/${encodeURIComponent(String(imei))}/order`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
