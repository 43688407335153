import { Component, OnInit, Input } from '@angular/core';
import { User, InputChangePassword } from 'app/core/api-swagger/identity-server';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ModalController } from '@ionic/angular';

import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { AuthService } from 'app/core/services/auth.service';

@Component({
  selector: 'app-users-change-password',
  templateUrl: './users-change-password.page.html',
  styleUrls: ['./users-change-password.page.scss'],
})
export class UsersChangePasswordPage implements OnInit {
  @Input() user: User;

  logged_user: User;
  form: FormGroup;
  button_cancel: string;
  button_done: string;

  validation_messages = {
    password: [{ type: 'required', message: this.translateService.instant(marker('USERS.VALIDATION.PASSWORD.REQUIRED')) }],
    confirm_password: [
      { type: 'required', message: this.translateService.instant(marker('USERS.VALIDATION.CONFIRM_PASSWORD.REQUIRED')) },
      { type: 'equalTo', message: this.translateService.instant(marker('USERS.VALIDATION.CONFIRM_PASSWORD.EQUAL_TO')) },
    ],
  };

  constructor(
    public formBuilder: FormBuilder,
    public modalController: ModalController,
    private translateService: TranslateService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      id: [this.user.id],
      password: [null, Validators.required],
      confirm_password: ['', Validators.compose([Validators.required, this.equalto('password')])],
    });
    this.button_cancel = this.translateService.instant(marker('BUTTON.CANCEL'));
    this.button_done = this.translateService.instant(marker('BUTTON.DONE'));
    this.logged_user = this.authService.user;
  }

  equalto(field_name: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const input = control.value;

      const isValid = control.root.value[field_name] === input;
      if (!isValid) {
        return { equalTo: { isValid } };
      } else {
        return null;
      }
    };
  }

  async handleOk(form_values: InputChangePassword) {
    await this.modalController.dismiss(form_values);
  }

  async handleCancel() {
    await this.modalController.dismiss();
  }
}
