import { createAction, props } from '@ngrx/store';
import { DataAvailability } from '../../models/DataAvailability';

export enum DataAvailabilityActionTypes {
  GetDataAvailabilityData = '[DataAvailability] Get data',
  GetDataAvailabilityDataSuccess = '[DataAvailability] Get data => 200',
  ClearDataAvailability = '[DataAvailability] Clear',
  CancelDataAvailabilityData = '[DataAvailability] Cancel',
}

export const get_data_availability_data = createAction(DataAvailabilityActionTypes.GetDataAvailabilityData, props<{ imei: number }>());
// eslint-disable-next-line max-len
export const get_data_availability_data_success = createAction(
  DataAvailabilityActionTypes.GetDataAvailabilityDataSuccess,
  props<{ data: DataAvailability }>()
);
export const clear_data_availability = createAction(DataAvailabilityActionTypes.ClearDataAvailability);
export const cancel_data_availability_data = createAction(DataAvailabilityActionTypes.CancelDataAvailabilityData);
