import { NgModule } from '@angular/core';
import { Routes, RouterModule, mapToCanActivate } from '@angular/router';
import { AuthGuard } from 'app/core/services/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./views/login/login.module').then(m => m.LoginPageModule),
  },
  {
    path: 'root',
    canActivate: mapToCanActivate([AuthGuard]),
    loadChildren: () => import('./views/root/tabs-root.module').then(m => m.TabsRootPageModule),
  },
  {
    path: 'users',
    canActivate: mapToCanActivate([AuthGuard]),
    data: { scopes: ['users:rw'] },
    loadChildren: () => import('./views/users/users.module').then(m => m.UsersPageModule),
  },
  {
    path: 'groups',
    canActivate: mapToCanActivate([AuthGuard]),
    loadChildren: () => import('./views/root/views/groups/groups.module').then(m => m.GroupsPageModule),
  },
  {
    path: 'roles',
    canActivate: mapToCanActivate([AuthGuard]),
    data: { scopes: ['roles:rw'] },
    loadChildren: () => import('./views/roles/roles.module').then(m => m.RolesPageModule),
  },
  {
    path: 'devices',
    canActivate: mapToCanActivate([AuthGuard]),
    data: { scopes: ['devices:rw', 'configurations:rw', 'maintenances:rw'] },
    loadChildren: () => import('./views/devices/devices.module').then(m => m.DevicesPageModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
