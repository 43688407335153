import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import * as fromTrackagri from './trackagri.reducer';
import { TrackagriEffects } from './trackagri.effects';
import { StoreModule } from '@ngrx/store';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromTrackagri.trackagriFeatureKey, fromTrackagri.reducer),
    EffectsModule.forFeature([TrackagriEffects]),
  ],
})
export class TrackagriNgrxModule {}
