<ion-app>
  <ion-header *ngIf="!production"><div class="preprod"></div></ion-header>

  <ion-menu side="start" menuId="first" contentId="content1" type="overlay">
    <ion-header>
      <ion-toolbar>
        <ion-img [src]="'assets/img/logo.png'" class="logo"></ion-img>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-list>
        <ion-item-group>
          <!-- <ion-item-divider>Section A</ion-item-divider> -->

          <ng-container *ngFor="let page of menu_content_entries">
            <ion-menu-toggle auto-hide="true" *ngIf="page.guard()">
              <ion-item [routerLink]="page.url" routerDirection="forward" [lines]="'none'">
                <ion-icon [name]="page.icon" slot="start" [color]="page.color"></ion-icon>
                <ion-text [color]="page.color">
                  {{ page.title | translate }}
                </ion-text>
              </ion-item>
            </ion-menu-toggle>
          </ng-container>
        </ion-item-group>

        <ion-item-group *ngIf="real_user$$ | async; let real_user">
          <ion-item-divider>{{ real_user?.name$$ | async }}</ion-item-divider>

          <ion-menu-toggle auto-hide="true">
            <ion-item [lines]="'none'" (click)="handleChangePassword()">
              <ion-icon name="lock-closed" slot="start" color="warning"></ion-icon>
              <ion-text color="warning">
                {{ 'MENU.ENTRY.CHANGE_PASSWORD' | translate }}
              </ion-text>
            </ion-item>
          </ion-menu-toggle>
        </ion-item-group>
      </ion-list>
    </ion-content>

    <ion-footer>
      <ion-toolbar>
        <ion-list lines="none">
          <ion-item>
            <ion-icon [name]="'package-variant-closed'" slot="start" color="dark"></ion-icon>
            <ion-text color="dark">{{ version$$ | async }}</ion-text>
          </ion-item>

          <ion-menu-toggle [autoHide]="true">
            <ion-item
              *ngFor="let page of menu_footer_entries"
              [disabled]="!page.url"
              [routerLink]="page.url"
              routerDirection="forward"
              (click)="page.click ? page.click() : undefined"
            >
              <ion-icon [name]="page.icon" slot="start" [color]="page.color"></ion-icon>
              <ion-text [color]="page.color">
                {{ page.title | translate }}
              </ion-text>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-toolbar>
    </ion-footer>
  </ion-menu>

  <ion-router-outlet id="content1"></ion-router-outlet>

  <ion-footer *ngIf="!production"><div class="preprod"></div></ion-footer>
</ion-app>
