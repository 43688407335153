import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable, catchError, throwError } from 'rxjs';

import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  // private readonly ALLOW_URLS = ['https://api.trackagri.datariver.fr']; // rancher 2 url
  private readonly ALLOW_URLS = ['https://api-agrilive.datariver.fr']; // argo url

  // private readonly FORBIDDEN_URLS = ['https://api.trackagri.datariver.fr/identity-server/authenticate']; // rancher  2 url
  private readonly FORBIDDEN_URLS = ['https://api-agrilive.datariver.fr/identity-server/authenticate']; // argo url

  // #region -> (interceptor basics)

  constructor(private router: Router, private readonly _authService: AuthService) {}

  // #endregion

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const request_url = req.url;

    let should_add_token = true;

    this.ALLOW_URLS.forEach(allowed_urls => {
      if (should_add_token === false) {
        return;
      }

      should_add_token = request_url.indexOf(allowed_urls) >= 0;
    });

    this.FORBIDDEN_URLS.forEach(forbidden_urls => {
      if (should_add_token === false) {
        return;
      }

      should_add_token = !(request_url.indexOf(forbidden_urls) >= 0);
    });

    const access_token = this._authService.access_token;

    // Special check for Zoho calls
    if (should_add_token) {
      req = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${access_token}`),
      });

      return next.handle(req);

      // return this._zohoAuthService.access_token$$.pipe(
      //   take(1),
      //   switchMap(access_token => {
      //     if (isNil(access_token)) {
      //       return throwError(() => new Error(get_i18n_for_zoho_error(ZohoError.NOT_AUTHENTICATED)));
      //     }

      //     req = req.clone({
      //       headers: req.headers.set('Authorization', `Zoho-oauthtoken ${access_token}`),
      //     });

      //     return next.handle(req);
      //   })
      // );
    }

    // Default handling
    return next.handle(req).pipe(
      catchError((error_response: unknown) => {
        // if (error_response instanceof HttpErrorResponse) {
        //   let error_msg = error_response.statusText;

        //   if (error_response?.error?.error) {
        //     error_msg += ' - ' + error_response?.error?.error;
        //   }

        //   if (error_response.status === 401 && error_response?.error?.error === 'invalid_token') {
        //     this.router.navigate(['', { outlets: { primary: 'login', modal: ['raz'] } }], {
        //       replaceUrl: true,
        //       queryParams: { logType: 'invalid-access-token' },
        //     });
        //   }

        //   captureMessage(`[${error_response?.status}/${error_response?.statusText}] ${error_response.message}`, 'error');
        // }

        return throwError(() => error_response);
      })
    );
  }
}
