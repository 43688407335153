import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';

/** */
@Injectable({
  providedIn: 'root',
})
export class StorageEngineService {
  // #region -> service basics

  /** */
  private _storage: Storage | null = null;

  /** */
  constructor(private storage: Storage) {}

  /** */
  public async initialize() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this._storage = storage;
  }

  // #endregion

  /** */
  public set(key: string, value: any) {
    this._storage.set(key, value);
  }

  /** */
  public get(key: string) {
    return this._storage.get(key);
  }

  /** */
  public remove(key: string) {
    return this._storage.remove(key);
  }
}
