import { Action, createReducer, on, createSelector, createFeatureSelector } from '@ngrx/store';
import * as Actions from './devices.actions';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Imeis } from '../../models/Trackagri';
import { TrackagriDevice } from 'app/models/devices/trackagri-device.class';

export const devicesFeatureKey = 'devices';

export interface DevicesState extends EntityState<TrackagriDevice> {
  downloaded: boolean;
}

export interface AppState {
  [devicesFeatureKey]: DevicesState;
}

export function selectId(a: TrackagriDevice): number {
  return a.imei;
}

export function sortByName(a: TrackagriDevice, b: TrackagriDevice): number {
  return (a.name || '').localeCompare(b.name || '');
}

export const deviceAdapter: EntityAdapter<TrackagriDevice> = createEntityAdapter<TrackagriDevice>({
  selectId: selectId,
  sortComparer: sortByName,
});

export const initialState: DevicesState = deviceAdapter.getInitialState({
  ids: [],
  entities: {},
  downloaded: false,
});

export const deviceReducer = createReducer(
  initialState,
  on(Actions.create_device_success, (state, { data }) => deviceAdapter.addOne(data, state)),
  on(Actions.read_devices, (state: DevicesState): DevicesState => ({ ...state, downloaded: false })),
  on(Actions.read_devices_success, (state, { data }) => ({
    ...state,
    ...deviceAdapter.setAll(data, state),
    downloaded: true,
  })),
  on(Actions.update_device_success, (state, { data }) => deviceAdapter.updateOne({ id: data.imei, changes: data }, state)),
  on(Actions.delete_device_success, (state, { key }) => deviceAdapter.removeOne(key, state))
);

export function reducer(state: DevicesState | undefined, action: Action) {
  return deviceReducer(state, action);
}

// before: migrate-ngrx-13
// export const selectDevicesFeature = createFeatureSelector<AppState, DevicesState>(devicesFeatureKey);
export const selectDevicesFeature = createFeatureSelector<DevicesState>(devicesFeatureKey);

export const selectDevices = createSelector(selectDevicesFeature, (state: DevicesState) => deviceAdapter.getSelectors().selectAll(state));

// before: migrate-ngrx-12
// export const selectDevicesForSubgroup = createSelector(
//   selectDevicesFeature,
//   (state: DevicesState, props) => deviceAdapter.getSelectors().selectAll(state)
//     .filter((value: TrackagriDevice) => value.comment === props.subgroup_id)
//     .sort((a: TrackagriDevice, b: TrackagriDevice) => (a.name || '').localeCompare(b.name || ''))
// );
export const selectDevicesForSubgroup = (subgroup_id: any) =>
  createSelector(selectDevicesFeature, state => {
    return deviceAdapter
      .getSelectors()
      .selectAll(state)
      .filter((value: TrackagriDevice) => value.comment === subgroup_id)
      .sort((a: TrackagriDevice, b: TrackagriDevice) => (a.name || '').localeCompare(b.name || ''));
  });

// before: migrate-ngrx-12
// export const selectDevice = createSelector(
//   selectDevicesFeature,
//   (state: DevicesState, props) => deviceAdapter.getSelectors().selectEntities(state)[props.imei]
// );
export const selectDevice = (imei: any) =>
  createSelector(selectDevicesFeature, (state: DevicesState) => deviceAdapter.getSelectors().selectEntities(state)[imei]);

export const selectDevicesDownloaded = createSelector(selectDevicesFeature, (state: DevicesState) => state.downloaded);

// before: migrate-ngrx-12
// export const selectDevicesForImeis = createSelector(
//   selectDevicesFeature,
//   (state: DevicesState, props: {imeis: Imeis}) => deviceAdapter.getSelectors().selectAll(state).filter((device) => props.imeis.findIndex((value) => value === device.imei) != -1)
// )
export const selectDevicesForImeis = (imeis: Imeis) =>
  createSelector(selectDevicesFeature, (state: DevicesState) =>
    deviceAdapter
      .getSelectors()
      .selectAll(state)
      .filter(device => imeis.findIndex(value => value === device.imei) != -1)
  );
