import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersChangePasswordPage } from './users-change-password/users-change-password.page';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationBarComponent } from './pagination-bar/pagination-bar.component';
import { DevicesCreateComponent } from './devices-create/devices-create.component';
import { DevicesUpdateComponent } from './devices-update/devices-update.component';
import { MultitouchDirective } from './multitouch.directive';
import { MeasurementSelectComponent } from './measurement-select/measurement-select.component';
import { SecuredImageComponent } from './secured-image/secured-image.component';
import { DurationSelectorComponent } from './duration-selector/duration-selector.component';
import { RawRangeSelectorComponent } from './raw-range-selector/raw-range-selector.component';
import { DevicesSelectPage } from './devices-select/devices-select.page';
import { CalendarModule } from 'ion7-calendar';
import { DateSelectPage } from './date-select/date-select.page';

@NgModule({
  imports: [CommonModule, IonicModule, TranslateModule.forChild(), FormsModule, ReactiveFormsModule, CalendarModule],
  declarations: [
    UsersChangePasswordPage,
    PaginationBarComponent,
    DevicesCreateComponent,
    DevicesUpdateComponent,
    MultitouchDirective,
    MeasurementSelectComponent,
    SecuredImageComponent,
    DurationSelectorComponent,
    RawRangeSelectorComponent,
    DevicesSelectPage,
    DateSelectPage,
  ],
  exports: [
    UsersChangePasswordPage,
    PaginationBarComponent,
    DevicesCreateComponent,
    DevicesUpdateComponent,
    MultitouchDirective,
    MeasurementSelectComponent,
    SecuredImageComponent,
    DurationSelectorComponent,
    RawRangeSelectorComponent,
    DevicesSelectPage,
    DateSelectPage,
  ],
})
export class SharedModule {}
