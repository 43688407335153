import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { DataAvailability } from '../../models/DataAvailability';
import { createReducer, on, createFeatureSelector, createSelector, Action } from '@ngrx/store';
import * as Actions from './data_availability.actions';
import { Imeis, Dates } from '../../models/Trackagri';

export const dataAvailabilityFeatureKey = 'data_availability';

export interface DataAvailabilityState extends EntityState<DataAvailability> {}

export interface AppState {
  [dataAvailabilityFeatureKey]: DataAvailabilityState;
}

export function selectId(a: DataAvailability): number {
  return a.imei;
}

export function sortComparer(a: DataAvailability, b: DataAvailability): number {
  return a.imei - b.imei;
}

export const dataAvailabilityAdapter: EntityAdapter<DataAvailability> = createEntityAdapter<DataAvailability>({
  selectId,
  sortComparer,
});

export const initialState: DataAvailabilityState = dataAvailabilityAdapter.getInitialState({
  ids: [],
  entities: {},
});

export const dataAvailabilityReducer = createReducer(
  initialState,
  on(Actions.get_data_availability_data, (state: DataAvailabilityState): DataAvailabilityState => ({ ...state })),
  on(Actions.get_data_availability_data_success, (state, { data }) => ({
    ...state,
    ...dataAvailabilityAdapter.upsertOne(data, state),
  })),
  on(Actions.clear_data_availability, state => ({
    ...dataAvailabilityAdapter.removeAll(state),
  }))
);

export function reducer(state: DataAvailabilityState | undefined, action: Action) {
  return dataAvailabilityReducer(state, action);
}

// before: migrate-ngrx-13
// export const selectDataAvailabilityFeature = createFeatureSelector<AppState, DataAvailabilityState>(dataAvailabilityFeatureKey);
export const selectDataAvailabilityFeature = createFeatureSelector<DataAvailabilityState>(dataAvailabilityFeatureKey);

// before: migrate-ngrx-12
// export const selectDataAvailability = createSelector(
//   selectDataAvailabilityFeature,
//   (state: DataAvailabilityState, props: {imei: number}) => dataAvailabilityAdapter.getSelectors().selectEntities(state)[props.imei]
// );
export const selectDataAvailability = (imei: number) =>
  createSelector(selectDataAvailabilityFeature, state => dataAvailabilityAdapter.getSelectors().selectEntities(state)[imei]);

export const selectAllDataAvailability = createSelector(selectDataAvailabilityFeature, (state: DataAvailabilityState) =>
  dataAvailabilityAdapter.getSelectors().selectAll(state)
);

export const selectAllDates = createSelector(selectDataAvailabilityFeature, (state: DataAvailabilityState) => {
  const allDataAvailability = dataAvailabilityAdapter.getSelectors().selectAll(state);
  let dates: any[] = [];
  for (const datum of allDataAvailability) {
    dates = [...dates.concat(datum.dates)];
  }
  dates.sort((a: Date, b: Date) => b.getTime() - a.getTime());
  return dates.filter((item, index) => dates.indexOf(item) === index);
});

// before: migrate-ngrx-12
// export const selectDatesForImeis = createSelector(
//   selectDataAvailabilityFeature,
//   (state: DataAvailabilityState, props: { imeis: Imeis }) => {
//     const allDataAvailability = dataAvailabilityAdapter
//       .getSelectors()
//       .selectAll(state);
//     const dataAvailabilityForImeis = allDataAvailability.filter(
//       (dataAvailability: DataAvailability) =>
//         props.imeis.find((value) => value === dataAvailability.imei)
//     );
//     let dates = [];
//     for (const datum of dataAvailabilityForImeis) {
//       dates = [...dates.concat(datum.dates)];
//     }
//     const sortedDates = dates
//       .map((date) => date.getTime())
//       .sort((a, b) => b - a)
//       .filter((date, index, datesArray) => datesArray.indexOf(date) === index)
//       .map((date) => new Date(date));

//     return sortedDates;
//   }
// );
export const selectDatesForImeis = (imeis: Imeis) =>
  createSelector(selectDataAvailabilityFeature, state => {
    const allDataAvailability = dataAvailabilityAdapter.getSelectors().selectAll(state);

    const dataAvailabilityForImeis = allDataAvailability.filter((dataAvailability: DataAvailability) =>
      imeis.find(value => value === dataAvailability.imei)
    );

    let dates: any[] = [];

    for (const datum of dataAvailabilityForImeis) {
      dates = [...dates.concat(datum.dates)];
    }

    const sortedDates = dates
      .map(date => date.getTime())
      .sort((a, b) => b - a)
      .filter((date, index, datesArray) => datesArray.indexOf(date) === index)
      .map(date => new Date(date));

    return sortedDates;
  });

// before: migrate-ngrx-12
// export const selectDatesForImeisAndDates = createSelector(
//   selectDataAvailabilityFeature,
//   (state: DataAvailabilityState, props: { imeis: Imeis; dates: Dates }) => {
//     const allDataAvailability = dataAvailabilityAdapter
//       .getSelectors()
//       .selectAll(state);
//     const dataAvailabilityForImeis = allDataAvailability.filter(
//       (dataAvailability: DataAvailability) =>
//         props.imeis.find((value) => value === dataAvailability.imei)
//     );
//     let dates = [];
//     for (const datum of dataAvailabilityForImeis) {
//       dates = [...dates.concat(datum.dates)];
//     }
//     const dateFrom = new Date(props.dates.date_from);
//     const dateTo = new Date(props.dates.date_to);
//     const sortedDates = dates
//       .map((date) => date.getTime())
//       .sort((a, b) => b - a)
//       .filter((date, index, datesArray) => datesArray.indexOf(date) === index)
//       .map((date) => new Date(date))
//       .filter((date: Date) => {
//         const afterFrom =
//           date > dateFrom || date.getTime() === dateFrom.getTime();
//         const beforeTo = date < dateTo || date.getTime() === dateTo.getTime();
//         return afterFrom && beforeTo;
//       });

//     return sortedDates;
//   }
// );
export const selectDatesForImeisAndDates = (imeis: Imeis, select_dates: Dates) =>
  createSelector(selectDataAvailabilityFeature, state => {
    const allDataAvailability = dataAvailabilityAdapter.getSelectors().selectAll(state);

    const dataAvailabilityForImeis = allDataAvailability.filter((dataAvailability: DataAvailability) =>
      imeis.find(value => value === dataAvailability.imei)
    );

    let dates: any[] = [];

    for (const datum of dataAvailabilityForImeis) {
      dates = [...dates.concat(datum.dates)];
    }

    const dateFrom = new Date(select_dates.date_from);
    const dateTo = new Date(select_dates.date_to);

    const sortedDates = dates
      .map(date => date.getTime())
      .sort((a, b) => b - a)
      .filter((date, index, datesArray) => datesArray.indexOf(date) === index)
      .map(date => new Date(date))
      .filter((date: Date) => {
        const afterFrom = date > dateFrom || date.getTime() === dateFrom.getTime();

        const beforeTo = date < dateTo || date.getTime() === dateTo.getTime();

        return afterFrom && beforeTo;
      });

    return sortedDates;
  });
