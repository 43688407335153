import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, map, tap } from 'rxjs';

import { isNil } from 'lodash';

import {
  BASE_PATH,
  UsersService,
  Configuration,
  InputCreateUser,
  InputUpdateUser,
  InputChangePassword,
  User as SwaggerUser,
} from 'app/core/api-swagger/identity-server';

import { User, Users } from 'app/models/user/classes/user';

import { MemoryCache } from '../../memory-cache';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UsersApiRawService extends UsersService {
  // #region -> (service basics)

  /** */
  private users_cache = new MemoryCache<User>();

  /** */
  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super(httpClient, environment.identityServerUrl, configuration);
  }

  // #endregion

  // #region -> create

  /** */
  public create_user$(user: InputCreateUser): Observable<User> {
    return this.identityServerUsersPost(user).pipe(
      map(swagger_user => {
        if (isNil(swagger_user)) {
          return null;
        }

        return this.deserialize(swagger_user);
      })
    );
  }

  // #endregion

  // #region -> read

  public fetch_users$(): Observable<Users> {
    return this.identityServerUsersGet().pipe(
      map(results => {
        const raw_users = results?.users ?? [];

        const users = this.deserialize_list(raw_users);
        const sorted_users = users.sort((a: User, b: User) => (a.name || '').localeCompare(b.name || ''));

        return sorted_users;
      })
    );
  }

  /** */
  public fetch_user$(id: string): Observable<User> {
    return this.identityServerUsersUserIdGet(id).pipe(
      map((raw_user: SwaggerUser) => {
        const user = this.deserialize(raw_user);
        return user;
      })
    );
  }

  // #endregion

  // #region -> update

  /** */
  public update_user$(id: string, user: InputUpdateUser): Observable<User> {
    return this.identityServerUsersUserIdPut(user, id).pipe(
      map(raw_user => {
        const user = this.deserialize(raw_user);
        return user;
      })
    );
  }

  /** */
  public update_user_password$(id: string, users_change_password: InputChangePassword): Observable<User> {
    return this.identityServerUsersUserIdPasswordPut(users_change_password, id).pipe(
      map(raw_user => {
        const user = this.deserialize(raw_user);
        return user;
      })
    );
  }

  // #endregion

  // #region -> delete

  /** */
  public delete_user$(id: string): Observable<any> {
    return this.identityServerUsersUserIdDelete(id).pipe(
      tap(() => {
        this.users_cache.remove(id.toString());
      })
    );
  }

  // #endregion

  /** */
  private deserialize(swagger_user: SwaggerUser): User {
    let user: User = null;
    const cached_user = this.users_cache.get(swagger_user?.id?.toString());

    if (!isNil(cached_user)) {
      user = cached_user;
      user.update(swagger_user);
    }

    user = new User();
    user.update(swagger_user);

    return user;
  }

  /** */
  private deserialize_list(raw_users: SwaggerUser[]): User[] {
    return raw_users.map(raw_device => this.deserialize(raw_device));
  }
}
