import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../../../environments/environment';

import {
  BASE_PATH,
  Configuration,
  TrackagriConfiguration,
  TrackagriConfigurations,
  TrackagriConfigurationsService,
} from 'app/core/api-swagger/trackagri-api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TrackagriConfigurationsApiRawService extends TrackagriConfigurationsService {
  // #region -> service basics

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super(httpClient, environment.trackagriApiUrl, configuration);
  }

  // #endregion

  // #region -> read

  /** */
  public fetch_configurations$(imei: number, date_from: string, date_to: string): Observable<TrackagriConfigurations> {
    return this.trackagriTrackagriConfigurationsImeiGet(imei, date_from, date_to);
  }

  // #endregion

  // #region -> update

  /** */
  public update_configuration$(body: TrackagriConfiguration, imei: number): Observable<TrackagriConfiguration> {
    return this.trackagriTrackagriConfigurationsImeiPost(body, imei);
  }

  // #endregion
}
