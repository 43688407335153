import { Injectable } from '@angular/core';

import {
  InputLogin,
  InputCreateRole,
  InputUpdateRole,
  InputCreateUser,
  InputUpdateUser,
  InputChangePassword,
} from 'app/core/api-swagger/identity-server';

import { IdentityServerApiRawService, RolesApiRawService, UsersApiRawService } from './raw';

@Injectable({
  providedIn: 'root',
})
export class IdentityServerApiService {
  // #region -> service basics

  constructor(
    private users_api: UsersApiRawService,
    private roles_api: RolesApiRawService,
    private identity_server: IdentityServerApiRawService
  ) {}

  // #endregion

  // #region -> create

  /** */
  public create_role$(role: InputCreateRole) {
    return this.roles_api.create_role$(role);
  }

  /** */
  public create_user$(user: InputCreateUser) {
    return this.users_api.create_user$(user);
  }

  // #endregion

  // #region -> read

  public fetch_role$(id: string) {
    return this.roles_api.fetch_role$(id);
  }

  public fetch_roles$(no_scopes?: boolean) {
    return this.roles_api.fetch_roles$(no_scopes);
  }

  public fetch_users$() {
    return this.users_api.fetch_users$();
  }

  /** */
  public fetch_user$(id: string) {
    return this.users_api.fetch_user$(id);
  }

  // #endregion

  // #region -> update

  /** */
  public update_role$(role: InputUpdateRole, id: string) {
    return this.roles_api.update_role$(role, id);
  }

  /** */
  public update_user$(id: string, user: InputUpdateUser) {
    return this.users_api.update_user$(id, user);
  }

  /** */
  public update_user_password$(id: string, users_change_password: InputChangePassword) {
    return this.users_api.update_user_password$(id, users_change_password);
  }

  // #endregion

  // #region -> delete

  /** */
  public delete_role$(id: string) {
    return this.roles_api.delete_role$(id);
  }

  /** */
  public delete_user$(id: string) {
    return this.users_api.delete_user$(id);
  }

  // #endregion

  /** */
  public authenticate$(user_login: InputLogin) {
    return this.identity_server.authenticate$(user_login);
  }
}
