import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import {
  BASE_PATH,
  BinaryFile,
  BinaryFiles,
  BinaryFilesService,
  Configuration,
  InputCreateBinaryFile,
  InputUpdateBinaryFile,
} from 'app/core/api-swagger/trackagri-api';

import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BinaryFilesApiRawService extends BinaryFilesService {
  // #region -> service basics

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super(httpClient, environment.trackagriApiUrl, configuration);
  }

  // #endregion

  // #region -> create

  /** */
  public create_binary_file$(input: InputCreateBinaryFile): Observable<BinaryFile> {
    return this.trackagriBinaryFilesPost(input);
  }

  // #endregion

  // #region -> read

  /** */
  public fetch_binary_files$(): Observable<BinaryFiles> {
    return this.trackagriBinaryFilesGet();
  }

  /** */
  public fetch_binary_file$(id: string): Observable<BinaryFile> {
    return this.trackagriBinaryFilesIdGet(id);
  }

  // #endregion

  // #region -> update

  /** */
  public update_binary_file$(input: InputUpdateBinaryFile, id: string): Observable<BinaryFile> {
    return this.trackagriBinaryFilesIdPut(input, id);
  }

  // #endregion

  // #region -> delete

  /** */
  public delete_binary_file$(id: string): Observable<any> {
    return this.trackagriBinaryFilesIdDelete(id);
  }

  // #endregion
}
