import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromTrackagriConfigurations from './trackagri_configurations.reducer';
import { TrackagriConfigurationsEffects } from './trackagri_configurations.effects';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromTrackagriConfigurations.trackagriConfigurationsFeatureKey, fromTrackagriConfigurations.reducer),
    EffectsModule.forFeature([TrackagriConfigurationsEffects]),
  ],
})
export class TrackagriConfigurationsNgrxModule {
  constructor() {}
}
