import { User as SwaggerUser, Group, RoleInfo, Subgroup } from 'app/core/api-swagger/identity-server';
import { forEach, isNil } from 'lodash';
import { BehaviorSubject, shareReplay } from 'rxjs';

/** */
export class User implements SwaggerUser {
  /**
   * User role.
   */
  role?: RoleInfo;

  /** */
  scopes?: string[];

  /** */
  last_login?: Date;

  /** */
  disabled?: boolean;

  // #region -> class basics

  /** */
  constructor() {}

  /** */
  public update(swagger_user: SwaggerUser): void {
    forEach(swagger_user, (value, key: keyof SwaggerUser, self) => {
      (this as any)[key] = value;
    });
  }

  // #endregion

  // #region -> entity basics

  /** */
  private _id$ = new BehaviorSubject<string>(null);

  /** */
  public id$$ = this._id$.asObservable().pipe(shareReplay({ refCount: true, bufferSize: 1 }));

  /** */
  public set id(id: string) {
    this._id$.next(id);
  }

  /** */
  public get id(): string {
    return this._id$.getValue();
  }

  /** */
  private _name$ = new BehaviorSubject<string>(null);

  /** */
  public name$$ = this._name$.asObservable().pipe(shareReplay({ refCount: true, bufferSize: 1 }));

  /** */
  public set name(name: string) {
    this._name$.next(name);
  }

  /** */
  public get name(): string {
    return this._name$.getValue();
  }

  /** */
  private _email$ = new BehaviorSubject<string>(null);

  /** */
  public email$$ = this._email$.asObservable().pipe(shareReplay({ refCount: true, bufferSize: 1 }));

  /** */
  public set email(email: string) {
    this._email$.next(email);
  }

  /** */
  public get email(): string {
    return this._email$.getValue();
  }

  // #endregion

  // #region -> group

  /** */
  group?: Group;

  /** */
  public hasGroup() {
    return !isNil(this.group);
  }

  // #endregion

  // #region -> subgroup

  /** */
  subgroup?: Subgroup;

  /** */
  public hasSubgroup() {
    return !isNil(this.subgroup);
  }

  // #endregion
}

/** */
export type Users = User[];
