import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';

import {
  MeasurementConfiguration,
  MeasurementConfigurationsService,
  InputUpsertMeasurementConfiguration,
  Configuration,
  BASE_PATH,
} from 'app/core/api-swagger/trackagri-api';
import { AuthService } from 'app/core/services/auth.service';

import { SelectedMeasurementFieldsCfg } from 'app/models';
import { User } from 'app/core/api-swagger/identity-server';

@Injectable({
  providedIn: 'root',
})
export class MeasurementsConfigurationApiRawService extends MeasurementConfigurationsService {
  // #region -> service basics

  /** */
  private user: User;

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    super(httpClient, environment.trackagriApiUrl, configuration);

    this.user = this.authService.user;
  }

  // #endregion

  // #region -> create

  /** */
  public create_measurement_config$(data: SelectedMeasurementFieldsCfg): Observable<MeasurementConfiguration> {
    const input: InputUpsertMeasurementConfiguration = {
      cfg: data,
      id: this.user.id,
    };

    return this.trackagriCfgPost(input);
  }

  // #endregion

  // #region -> read

  /** */
  public fetch_measurements_config$(): Observable<MeasurementConfiguration> {
    return this.trackagriCfgIdGet(this.user.id);
  }

  // #endregion
}
