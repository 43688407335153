/**
 * device-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: release-v2024-01-r0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AckConfiguration } from '../model/ackConfiguration';
import { AckConfigurationHistoryReturn } from '../model/ackConfigurationHistoryReturn';
import { DeleteoOrUpdateConfigurationReturn } from '../model/deleteoOrUpdateConfigurationReturn';
import { GetConfiguration } from '../model/getConfiguration';
import { GetConfigurationEmbedded } from '../model/getConfigurationEmbedded';
import { GetConfigurationReturn } from '../model/getConfigurationReturn';
import { GetConfigurationServer } from '../model/getConfigurationServer';
import { GetNextConfiguration } from '../model/getNextConfiguration';
import { ListTmpConfigurationReturn } from '../model/listTmpConfigurationReturn';
import { OrderedError } from '../model/orderedError';
import { PostAckConfiguration } from '../model/postAckConfiguration';
import { PostAckConfigurationReturn } from '../model/postAckConfigurationReturn';
import { PostTmpConfiguration } from '../model/postTmpConfiguration';
import { PostTmpConfigurationReturn } from '../model/postTmpConfigurationReturn';
import { PutTmpConfiguration } from '../model/putTmpConfiguration';
import { UpdateConfigurationEmbedded } from '../model/updateConfigurationEmbedded';
import { UpdateConfigurationServer } from '../model/updateConfigurationServer';
import { UpdatedConfigurationEmbedded } from '../model/updatedConfigurationEmbedded';
import { UpdatedConfigurationServer } from '../model/updatedConfigurationServer';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ConfigurationsService {

    protected basePath = 'http://0.0.0.0:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Delete one temporary configurations
     * 
     * @param imei 
     * @param cid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected deleteTmpConfiguration(imei: number, cid: string, observe?: 'body', reportProgress?: boolean): Observable<DeleteoOrUpdateConfigurationReturn>;
    protected deleteTmpConfiguration(imei: number, cid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeleteoOrUpdateConfigurationReturn>>;
    protected deleteTmpConfiguration(imei: number, cid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeleteoOrUpdateConfigurationReturn>>;
    protected deleteTmpConfiguration(imei: number, cid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling deleteTmpConfiguration.');
        }

        if (cid === null || cid === undefined) {
            throw new Error('Required parameter cid was null or undefined when calling deleteTmpConfiguration.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<DeleteoOrUpdateConfigurationReturn>(`${this.basePath}/devices/${encodeURIComponent(String(imei))}/configuration/tmp/${encodeURIComponent(String(cid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get device full configuration (server, embeded and ack)
     * 
     * @param imei 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getConfiguration(imei: number, observe?: 'body', reportProgress?: boolean): Observable<GetConfiguration>;
    protected getConfiguration(imei: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetConfiguration>>;
    protected getConfiguration(imei: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetConfiguration>>;
    protected getConfiguration(imei: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling getConfiguration.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetConfiguration>(`${this.basePath}/devices/${encodeURIComponent(String(imei))}/configuration`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get last acked configuration
     * 
     * @param imei 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getConfigurationAck(imei: number, observe?: 'body', reportProgress?: boolean): Observable<AckConfiguration>;
    protected getConfigurationAck(imei: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AckConfiguration>>;
    protected getConfigurationAck(imei: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AckConfiguration>>;
    protected getConfigurationAck(imei: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling getConfigurationAck.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AckConfiguration>(`${this.basePath}/devices/${encodeURIComponent(String(imei))}/configuration/ack`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get acked configuration history
     * 
     * @param imei 
     * @param start 
     * @param end 
     * @param query Query to filter devices (stringified from json). Note: fields ending with &#39;_date&#39; will be parsed to date (isoformat).
     * @param offset Paging offset.
     * @param limit Max number of devices to return.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getConfigurationAckHistory(imei: number, start?: Date, end?: Date, query?: string, offset?: number, limit?: number, observe?: 'body', reportProgress?: boolean): Observable<AckConfigurationHistoryReturn>;
    protected getConfigurationAckHistory(imei: number, start?: Date, end?: Date, query?: string, offset?: number, limit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AckConfigurationHistoryReturn>>;
    protected getConfigurationAckHistory(imei: number, start?: Date, end?: Date, query?: string, offset?: number, limit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AckConfigurationHistoryReturn>>;
    protected getConfigurationAckHistory(imei: number, start?: Date, end?: Date, query?: string, offset?: number, limit?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling getConfigurationAckHistory.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (start !== undefined && start !== null) {
            queryParameters = queryParameters.set('start', <any>start.toISOString());
        }
        if (end !== undefined && end !== null) {
            queryParameters = queryParameters.set('end', <any>end.toISOString());
        }
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AckConfigurationHistoryReturn>(`${this.basePath}/devices/${encodeURIComponent(String(imei))}/configuration/ack/history`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get embedded configuration
     * Note: it returns the saved configuration, this may differs from the actual configuration ack by the device !
     * @param imei 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getConfigurationEmbedded(imei: number, observe?: 'body', reportProgress?: boolean): Observable<GetConfigurationEmbedded>;
    protected getConfigurationEmbedded(imei: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetConfigurationEmbedded>>;
    protected getConfigurationEmbedded(imei: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetConfigurationEmbedded>>;
    protected getConfigurationEmbedded(imei: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling getConfigurationEmbedded.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetConfigurationEmbedded>(`${this.basePath}/devices/${encodeURIComponent(String(imei))}/configuration/embedded`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get device next embedded configuration
     * 
     * @param imei 
     * @param force Optional force parameter to force returning the current configuration.
     * @param subdevice 
     * @param msg 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getConfigurationNext(imei: number, force?: boolean, subdevice?: string, msg?: string, observe?: 'body', reportProgress?: boolean): Observable<GetNextConfiguration>;
    protected getConfigurationNext(imei: number, force?: boolean, subdevice?: string, msg?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetNextConfiguration>>;
    protected getConfigurationNext(imei: number, force?: boolean, subdevice?: string, msg?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetNextConfiguration>>;
    protected getConfigurationNext(imei: number, force?: boolean, subdevice?: string, msg?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling getConfigurationNext.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (force !== undefined && force !== null) {
            queryParameters = queryParameters.set('force', <any>force);
        }
        if (subdevice !== undefined && subdevice !== null) {
            queryParameters = queryParameters.set('subdevice', <any>subdevice);
        }
        if (msg !== undefined && msg !== null) {
            queryParameters = queryParameters.set('msg', <any>msg);
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetNextConfiguration>(`${this.basePath}/devices/${encodeURIComponent(String(imei))}/configuration/next`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get device&#39;s server configuration
     * 
     * @param imei 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getConfigurationServer(imei: number, observe?: 'body', reportProgress?: boolean): Observable<GetConfigurationServer>;
    protected getConfigurationServer(imei: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetConfigurationServer>>;
    protected getConfigurationServer(imei: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetConfigurationServer>>;
    protected getConfigurationServer(imei: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling getConfigurationServer.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetConfigurationServer>(`${this.basePath}/devices/${encodeURIComponent(String(imei))}/configuration/server`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get one temporary configuration
     * 
     * @param imei 
     * @param cid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getTmpConfiguration(imei: number, cid: string, observe?: 'body', reportProgress?: boolean): Observable<GetConfigurationReturn>;
    protected getTmpConfiguration(imei: number, cid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetConfigurationReturn>>;
    protected getTmpConfiguration(imei: number, cid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetConfigurationReturn>>;
    protected getTmpConfiguration(imei: number, cid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling getTmpConfiguration.');
        }

        if (cid === null || cid === undefined) {
            throw new Error('Required parameter cid was null or undefined when calling getTmpConfiguration.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetConfigurationReturn>(`${this.basePath}/devices/${encodeURIComponent(String(imei))}/configuration/tmp/${encodeURIComponent(String(cid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List device temporary configurations
     * 
     * @param imei 
     * @param type Filter tmp conf of a given type
     * @param all If set, also return the past tmp conf
     * @param now If set, returns tmp configuration that are active right now
     * @param at_date returns tmp configuration that are active at the given date
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected listTmpConfigurations(imei: number, type?: string, all?: boolean, now?: boolean, at_date?: Date, observe?: 'body', reportProgress?: boolean): Observable<ListTmpConfigurationReturn>;
    protected listTmpConfigurations(imei: number, type?: string, all?: boolean, now?: boolean, at_date?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListTmpConfigurationReturn>>;
    protected listTmpConfigurations(imei: number, type?: string, all?: boolean, now?: boolean, at_date?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListTmpConfigurationReturn>>;
    protected listTmpConfigurations(imei: number, type?: string, all?: boolean, now?: boolean, at_date?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling listTmpConfigurations.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }
        if (all !== undefined && all !== null) {
            queryParameters = queryParameters.set('all', <any>all);
        }
        if (now !== undefined && now !== null) {
            queryParameters = queryParameters.set('now', <any>now);
        }
        if (at_date !== undefined && at_date !== null) {
            queryParameters = queryParameters.set('at_date', <any>at_date.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ListTmpConfigurationReturn>(`${this.basePath}/devices/${encodeURIComponent(String(imei))}/configuration/tmp`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post an acked configuration
     * This should be use by &#x60;device_configuration_handler&#x60; to indicate that the device has ack this configuration.
     * @param imei 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected postConfigurationAck(imei: number, body: PostAckConfiguration, observe?: 'body', reportProgress?: boolean): Observable<PostAckConfigurationReturn>;
    protected postConfigurationAck(imei: number, body: PostAckConfiguration, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostAckConfigurationReturn>>;
    protected postConfigurationAck(imei: number, body: PostAckConfiguration, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostAckConfigurationReturn>>;
    protected postConfigurationAck(imei: number, body: PostAckConfiguration, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling postConfigurationAck.');
        }

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling postConfigurationAck.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PostAckConfigurationReturn>(`${this.basePath}/devices/${encodeURIComponent(String(imei))}/configuration/ack`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a tmp configuration
     * 
     * @param imei 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected postTmpConfiguration(imei: number, body: PostTmpConfiguration, observe?: 'body', reportProgress?: boolean): Observable<PostTmpConfigurationReturn>;
    protected postTmpConfiguration(imei: number, body: PostTmpConfiguration, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostTmpConfigurationReturn>>;
    protected postTmpConfiguration(imei: number, body: PostTmpConfiguration, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostTmpConfigurationReturn>>;
    protected postTmpConfiguration(imei: number, body: PostTmpConfiguration, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling postTmpConfiguration.');
        }

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling postTmpConfiguration.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PostTmpConfigurationReturn>(`${this.basePath}/devices/${encodeURIComponent(String(imei))}/configuration/tmp`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the embedded configuration
     * 
     * @param imei 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected putConfigurationEmbedded(imei: number, body: UpdateConfigurationEmbedded, observe?: 'body', reportProgress?: boolean): Observable<UpdatedConfigurationEmbedded>;
    protected putConfigurationEmbedded(imei: number, body: UpdateConfigurationEmbedded, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UpdatedConfigurationEmbedded>>;
    protected putConfigurationEmbedded(imei: number, body: UpdateConfigurationEmbedded, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UpdatedConfigurationEmbedded>>;
    protected putConfigurationEmbedded(imei: number, body: UpdateConfigurationEmbedded, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling putConfigurationEmbedded.');
        }

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling putConfigurationEmbedded.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<UpdatedConfigurationEmbedded>(`${this.basePath}/devices/${encodeURIComponent(String(imei))}/configuration/embedded`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update device&#39;s server configuration
     * 
     * @param imei 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected putConfigurationServer(imei: number, body: UpdateConfigurationServer, observe?: 'body', reportProgress?: boolean): Observable<UpdatedConfigurationServer>;
    protected putConfigurationServer(imei: number, body: UpdateConfigurationServer, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UpdatedConfigurationServer>>;
    protected putConfigurationServer(imei: number, body: UpdateConfigurationServer, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UpdatedConfigurationServer>>;
    protected putConfigurationServer(imei: number, body: UpdateConfigurationServer, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling putConfigurationServer.');
        }

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling putConfigurationServer.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<UpdatedConfigurationServer>(`${this.basePath}/devices/${encodeURIComponent(String(imei))}/configuration/server`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update one temporary configurations
     * 
     * @param imei 
     * @param cid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected updateTmpConfiguration(imei: number, cid: string, body: PutTmpConfiguration, observe?: 'body', reportProgress?: boolean): Observable<DeleteoOrUpdateConfigurationReturn>;
    protected updateTmpConfiguration(imei: number, cid: string, body: PutTmpConfiguration, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeleteoOrUpdateConfigurationReturn>>;
    protected updateTmpConfiguration(imei: number, cid: string, body: PutTmpConfiguration, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeleteoOrUpdateConfigurationReturn>>;
    protected updateTmpConfiguration(imei: number, cid: string, body: PutTmpConfiguration, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling updateTmpConfiguration.');
        }

        if (cid === null || cid === undefined) {
            throw new Error('Required parameter cid was null or undefined when calling updateTmpConfiguration.');
        }

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateTmpConfiguration.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<DeleteoOrUpdateConfigurationReturn>(`${this.basePath}/devices/${encodeURIComponent(String(imei))}/configuration/tmp/${encodeURIComponent(String(cid))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
