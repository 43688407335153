import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import {
  BASE_PATH,
  Configuration,
  ConfigurationsService,
  GetConfigurationEmbedded,
  UpdateConfigurationEmbedded,
} from 'app/core/api-swagger/device-api';

import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DeviceConfigurationApiService extends ConfigurationsService {
  // #region -> service basics

  basePath: string = environment.trackagriApiUrl;

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);
  }

  // #endregion

  // #region -> read

  /** */
  public fetch_device_configuration$(imei: number): Observable<GetConfigurationEmbedded> {
    return this.getConfigurationEmbedded(imei);
  }

  // #endregion

  // #region -> update

  /** */
  public update_device_configuration$(imei: number, body: UpdateConfigurationEmbedded): Observable<any> {
    return this.putConfigurationEmbedded(imei, body);
  }

  // #endregion
}
