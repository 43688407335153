import { forEach, isNil } from 'lodash';

import { BehaviorSubject, combineLatest, map, shareReplay } from 'rxjs';

import {
  Dynamic,
  ProdLog,
  ComputeBat,
  Subscription,
  ComputeProdRef,
  ComputeSupports,
  BaseDeviceMetaData,
  DeviceLastMeasurement,
  DynamicConfiguration,
  GatewayDeviceLastData,
  ProdStepResultSummary,
} from 'app/core/api-swagger/device-api';

export class TrackagriDevice implements Dynamic {
  imei: number;
  sn?: string;
  name?: string;
  type?: string;
  cdate?: Date;
  comment?: string;
  metadata?: BaseDeviceMetaData;
  owner?: number;
  configuration?: DynamicConfiguration;
  subscriptions?: Subscription[];
  bat?: ComputeBat;
  supports?: ComputeSupports;
  prod_ref?: ComputeProdRef;
  prod_summary?: { [key: string]: { [key: string]: ProdStepResultSummary } };
  prod_logs?: ProdLog[];

  // #region -> class basics

  /** */
  constructor() {}

  /** */
  public update(raw_device: Dynamic): void {
    forEach(raw_device, (value, key: keyof Dynamic, self) => {
      (this as any)[key] = value;
    });
  }

  // #endregion

  // #region -> last data / measurements

  /**
   * @deprecated Will no longer work after server migration
   */
  private _last_data$ = new BehaviorSubject<GatewayDeviceLastData>(null);

  /**
   * @deprecated Will no longer work after server migration
   */
  public last_data$$ = this._last_data$.pipe(shareReplay({ bufferSize: 1, refCount: true }));

  /**
   * @deprecated Will no longer work after server migration
   */
  public set last_data(last_data: GatewayDeviceLastData) {
    if (!isNil(last_data?.last_contact)) {
      last_data.last_contact = new Date(last_data.last_contact + '.000Z');
    }

    this._last_data$.next(last_data);
  }

  /**
   * @deprecated Will no longer work after server migration
   */
  public get last_data(): GatewayDeviceLastData {
    return this._last_data$.getValue();
  }

  /** */
  private _last_measurements$ = new BehaviorSubject<{ [key: string]: DeviceLastMeasurement }>(null);

  /** */
  public last_measurements$$ = this._last_measurements$.pipe(shareReplay({ bufferSize: 1, refCount: true }));

  /** */
  public set last_measurements(last_measurements: { [key: string]: DeviceLastMeasurement }) {
    this._last_measurements$.next(last_measurements);
  }

  /** */
  public get last_measurements(): { [key: string]: DeviceLastMeasurement } {
    return this._last_measurements$.getValue();
  }

  /** */
  public gateway_message$$ = combineLatest({ last_data: this.last_data$$, last_measurements: this.last_measurements$$ }).pipe(
    map(({ last_data, last_measurements }) => {
      if (!isNil(last_measurements)) {
        return last_measurements;
      }

      return last_data;
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  /** */
  public last_contact$$ = combineLatest({ last_data: this.last_data$$, last_measurements: this.last_measurements$$ }).pipe(
    map(({ last_data, last_measurements }) => {
      return last_measurements?.['gateway_message']?.time ?? last_data?.last_contact;
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  /** */
  public last_vbat$$ = combineLatest({ last_data: this.last_data$$, last_measurements: this.last_measurements$$ }).pipe(
    map(({ last_data, last_measurements }) => {
      const last_data_vbat = last_data?.measures?.gateway_message?.vbat ?? null;
      const last_measurements_vbat = last_measurements?.['gateway_message']?.fields?.['vbat']?.last ?? null;

      return last_measurements_vbat ?? last_data_vbat;
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  /** */
  public last_valim$$ = combineLatest({ last_data: this.last_data$$, last_measurements: this.last_measurements$$ }).pipe(
    map(({ last_data, last_measurements }) => {
      const last_data_valim = last_data?.measures?.gateway_message?.valim ?? null;
      const last_measurements_valim = last_measurements?.['gateway_message']?.fields?.['valim']?.last ?? null;

      return last_measurements_valim ?? last_data_valim;
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  /** */
  public last_temperature_micro$$ = combineLatest({ last_data: this.last_data$$, last_measurements: this.last_measurements$$ }).pipe(
    map(({ last_data, last_measurements }) => {
      const last_data_temperature_micro = last_data?.measures?.gateway_message?.temperature_micro ?? null;
      const last_measurements_temperature_micro = last_measurements?.['gateway_message']?.fields?.['temperature_micro']?.last ?? null;

      return last_measurements_temperature_micro ?? last_data_temperature_micro;
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  /** */
  public last_gprs_qos$$ = combineLatest({ last_data: this.last_data$$, last_measurements: this.last_measurements$$ }).pipe(
    map(({ last_data, last_measurements }) => {
      const last_data_value = last_data?.measures?.gateway_message?.gprs_qos ?? null;
      const last_measurements_value = last_measurements?.['gateway_message']?.fields?.['gprs_qos']?.last ?? null;

      return last_measurements_value ?? last_data_value;
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  // #endregion

  // #region -> version

  /** */
  public hardware_version$$ = combineLatest({ last_data: this.last_data$$, last_measurements: this.last_measurements$$ }).pipe(
    map(({ last_data, last_measurements }) => {
      const last_data_value = last_data?.version?.hardware ?? null;
      const last_measurements_value = last_measurements?.['gateway_message']?.tags?.['hardware_version'] ?? null;

      return last_measurements_value ?? last_data_value;
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  /** */
  public software_version$$ = combineLatest({ last_data: this.last_data$$, last_measurements: this.last_measurements$$ }).pipe(
    map(({ last_data, last_measurements }) => {
      const last_data_value = last_data?.version?.software ?? null;
      const last_measurements_value = last_measurements?.['gateway_message']?.tags?.['software_version'] ?? null;

      return last_measurements_value ?? last_data_value;
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  // #endregion
}
