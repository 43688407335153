import { Injectable } from '@angular/core';

import { PostDevices, UpdateConfigurationEmbedded, UpdateDevice, UpdateOrder } from 'app/core/api-swagger/device-api';

import { OrdersApiRawService, DevicesApiRawService, DeviceConfigurationApiService } from './raw';

@Injectable({
  providedIn: 'root',
})
export class DevicesApiService {
  // #region -> service basics

  constructor(
    private order_api: OrdersApiRawService,
    private devices_api: DevicesApiRawService,
    private device_configurations_api: DeviceConfigurationApiService
  ) {}

  // #endregion

  // #region -> create

  /** */
  public create_device$(body: PostDevices) {
    return this.devices_api.create_device$(body);
  }

  // #endregion

  // #region -> read

  /** */
  public fetch_configuration$(imei: number) {
    return this.device_configurations_api.fetch_device_configuration$(imei);
  }

  /** */
  public fetch_device$(imei: number) {
    return this.devices_api.fetch_device$(imei);
  }

  /** */
  public fetch_devices$(group_id?: string[]) {
    return this.devices_api.fetch_devices$(group_id);
  }

  // #endregion

  // #region -> update

  /** */
  public update_configuration$(imei: number, body: UpdateConfigurationEmbedded) {
    return this.device_configurations_api.update_device_configuration$(imei, body);
  }

  /** */
  public update_device$(imei: number, body: UpdateDevice) {
    return this.devices_api.update_device$(imei, body);
  }

  /** */
  public set_order$(imei: number, value: UpdateOrder) {
    return this.order_api.set_order$(imei, value);
  }

  // #endregion

  // #region -> delete

  /** */
  public delete_device$(imei: number) {
    return this.devices_api.delete_device$(imei);
  }

  // #endregion
}
