import { IEnvironment, default_environment } from './default';

export const environment: IEnvironment = {
  ...default_environment,
  env: 'Production',
  environment: 'prod',
  production: true,

  // rancher 2 urls
  // devicesApiUrl: 'https://api.trackagri.datariver.fr',
  // measurementsApiUrl: 'https://api.trackagri.datariver.fr',
  // identityServerUrl: 'https://api.trackagri.datariver.fr',
  // trackagriApiUrl: 'https://api.trackagri.datariver.fr',

  // argo urls
  devicesApiUrl: 'https://api-agrilive.datariver.fr',
  measurementsApiUrl: 'https://api-agrilive.datariver.fr',
  identityServerUrl: 'https://api-agrilive.datariver.fr',
  trackagriApiUrl: 'https://api-agrilive.datariver.fr',

  google_maps: {
    api_key: 'AIzaSyB2QyMbvZVSIxk__34wuSgAiCrE7T_3wxc',
    version: default_environment.google_maps.version
  },

  sentry: {
    release_version: default_environment.sentry.release_version,
    dsn: 'https://46adf9be4eadd745ac4759c87a027db0@sentry.siconsult.cloud/31',
  },
};
