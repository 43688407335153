import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';
import { NavController } from '@ionic/angular';
import { Observable, map, of, switchMap, take, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  // #region -> service basics

  constructor(private authService: AuthService, private navCtrl: NavController) {}

  // #endregion

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const url: string = state.url;

    return this.checkLogin(url).pipe(
      switchMap(check_login => {
        if (next.data && (next.data as any).scope) {
          return this.hasOneScope((next.data as any).scopes, state.url);
        }

        return of(check_login);
      }),
      take(1)
    );
  }

  /** */
  private hasOneScope(scopes: [string], url: string): Observable<boolean> {
    if (this.authService.hasScope('admin')) {
      return of(true);
    }

    for (const scope of scopes) {
      const has_scope = this.authService.hasScope(scope);

      if (has_scope) {
        return of(true);
      }
    }

    console.warn(`ACCESS DENIED to ${url}`);
    return of(false);
  }

  /** */
  private checkLogin(url: string): Observable<boolean> {
    return this.authService.isAuthenticated().pipe(
      tap(is_authenticated => {
        if (!is_authenticated) {
          console.warn(`ACCESS DENIED to ${url}`);
          console.warn(`Navigate to /login`);

          this.navCtrl.navigateRoot(['/login']);
        }
      })
    );
  }
}
