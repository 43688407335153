import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BASE_PATH, Configuration, IdentityServerService, InputLogin } from 'app/core/api-swagger/identity-server';

import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IdentityServerApiRawService extends IdentityServerService {
  // #region -> (service basics)

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super(httpClient, environment.identityServerUrl, configuration);
  }

  // #endregion

  /** */
  public authenticate$(user_login: InputLogin): Observable<any> {
    return this.identityServerAuthenticatePost(user_login);
  }
}
