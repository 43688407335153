import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, tap, map, mergeMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import * as TrackagriActions from './trackagri.actions';
import { MeasurementsApiService } from 'app/core/api';

@Injectable()
export class TrackagriEffects {
  getTrackagriData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TrackagriActions.get_trackagri_data),
      tap(action => console.log({ effect: action.type, action })),
      mergeMap(props =>
        this.measurementsApiService.fetch_device_timeseries$(props.input_data).pipe(
          map(data => TrackagriActions.get_trackagri_data_success({ data: data })),
          catchError(error => {
            console.log({ error });
            return EMPTY;
          })
        )
      )
    );
  });

  constructor(private actions$: Actions, private measurementsApiService: MeasurementsApiService) {}
}
