import { EntityState, EntityAdapter, createEntityAdapter, Dictionary } from '@ngrx/entity';
import { Timeserie } from 'app/core/api-swagger/measurement_api/model/models';
import { createReducer, on, createFeatureSelector, createSelector, Action } from '@ngrx/store';
import * as Actions from './trackagri.actions';
import { Imeis, Dates } from '../../models/Trackagri';
import * as _ from 'lodash';

export const trackagriFeatureKey = 'trackagri';

export interface TrackagriState extends EntityState<Timeserie> {
  downloaded: Imeis;
  imeis: Imeis;
  dates: Dates;
}

export interface AppState {
  [trackagriFeatureKey]: TrackagriState;
}

export function selectId(a: Timeserie): number {
  return a.imei;
}

export function sortByName(a: Timeserie, b: Timeserie): number {
  return a.imei - b.imei;
}

export const trackagriAdapter: EntityAdapter<Timeserie> = createEntityAdapter<Timeserie>({
  selectId: selectId,
  sortComparer: sortByName,
});

export const initialState: TrackagriState = trackagriAdapter.getInitialState({
  ids: [],
  entities: {},
  downloaded: [],
  imeis: [],
  dates: {
    date_from: undefined,
    date_to: undefined,
  },
});

export const trackagriReducer = createReducer(
  initialState,
  on(
    Actions.clear_trackagri_downloaded,
    (state): TrackagriState => ({
      ...state,
      ids: [],
      entities: {},
      downloaded: [],
    })
  ),
  on(Actions.get_trackagri_data, (state): TrackagriState => ({ ...state })),
  on(Actions.get_trackagri_data_success, (state, { data }) => ({
    ...state,
    ...trackagriAdapter.upsertOne(data, state),
    downloaded: [...state.downloaded, data.imei],
  })),
  on(
    Actions.update_imeis_trackagri,
    (state, { imeis }): TrackagriState => ({
      ...state,
      imeis,
    })
  ),
  on(
    Actions.update_dates_trackagri,
    (state, { dates }): TrackagriState => ({
      ...state,
      dates,
    })
  )
);

export function reducer(state: TrackagriState | undefined, action: Action) {
  return trackagriReducer(state, action);
}

// before: migrate-ngrx-13
// export const selectTrackagriFeature = createFeatureSelector<AppState, TrackagriState>(trackagriFeatureKey);
export const selectTrackagriFeature = createFeatureSelector<TrackagriState>(trackagriFeatureKey);

export const selectAllTrackagris = createSelector(selectTrackagriFeature, (state: TrackagriState) =>
  trackagriAdapter.getSelectors().selectAll(state)
);

// before: migrate-ngrx-12
// export const selectTrackagri = createSelector(
//   selectTrackagriFeature,
//   (state: TrackagriState, props) => trackagriAdapter.getSelectors().selectEntities(state)[props.imei]
// );
export const selectTrackagri = (imei: any) =>
  createSelector(selectTrackagriFeature, (state: TrackagriState) => trackagriAdapter.getSelectors().selectEntities(state)[imei]);

// before: migrate-ngrx-12
// export const selectTrackagris = createSelector(
//   selectTrackagriFeature,
//   (state: TrackagriState, props: {imeis: Imeis}) => ([...trackagriAdapter.getSelectors().selectAll(state).filter((timeserie: Timeserie) => props.imeis.find(imei => imei === timeserie.imei))])
// );
export const selectTrackagris = (imeis: Imeis) =>
  createSelector(selectTrackagriFeature, (state: TrackagriState) => [
    ...trackagriAdapter
      .getSelectors()
      .selectAll(state)
      .filter((timeserie: Timeserie) => imeis.find(imei => imei === timeserie.imei)),
  ]);

// before: migrate-ngrx-12
// export const selectTrackagrisEntities = createSelector(
//   selectTrackagriFeature,
//   (state: TrackagriState, props: {imeis: Imeis}) => (_.pick(trackagriAdapter.getSelectors().selectEntities(state), props.imeis)) as Dictionary<Timeserie>
// );
export const selectTrackagrisEntities = (imeis: Imeis) =>
  createSelector(
    selectTrackagriFeature,
    (state: TrackagriState) => _.pick(trackagriAdapter.getSelectors().selectEntities(state), imeis) as Dictionary<Timeserie>
  );

export const selectTrackagrisDownloaded = createSelector(selectTrackagriFeature, (state: TrackagriState) => state.downloaded);

export const selectTrackagriImeis = createSelector(selectTrackagriFeature, (state: TrackagriState) => state.imeis);

export const selectTrackagriDates = createSelector(selectTrackagriFeature, (state: TrackagriState) => state.dates);
